import { getAuth } from "firebase/auth";
import { List, editListProperty, isFrozen } from "../../pages/List/list-model";
import "./ListSettings.css";

export function ListSettings({ list, listID }: { list: List; listID: string }) {
	const currentUserID = getAuth().currentUser?.uid || "";

	function onListOwnerCanTakeInput(event: Event) {
		if (!(event.target instanceof HTMLInputElement)) return;
		editListProperty(listID, "ownerCanTake", event.target.checked);
	}

	function onListOwnerCanSee(event: Event) {
		if (!(event.target instanceof HTMLInputElement)) return;
		editListProperty(listID, "ownerCanSee", event.target.checked);
	}

	function onInviteesCanSee(event: Event) {
		if (!(event.target instanceof HTMLInputElement)) return;
		editListProperty(listID, "inviteesCanSee", event.target.checked);
	}

	return (
		<div class="list-settings">
			<label for="list-owner-can-take">List owner can take an item</label>
			<input
				id="list-owner-can-take"
				type="checkbox"
				role="switch"
				onInput={onListOwnerCanTakeInput}
				checked={list.ownerCanTake}
				disabled={list.owner !== currentUserID || isFrozen(list)}></input>

			<label for="list-owner-can-see">List owner can see who has taken an item</label>
			<input
				id="list-owner-can-see"
				type="checkbox"
				role="switch"
				onInput={onListOwnerCanSee}
				checked={list.ownerCanSee}
				disabled={list.owner !== currentUserID || isFrozen(list)}></input>

			<label for="invitee-can-see">Invitees can see who has taken an item</label>
			<input
				id="invitee-can-see"
				type="checkbox"
				role="switch"
				onInput={onInviteesCanSee}
				checked={list.inviteesCanSee}
				disabled={list.owner !== currentUserID || isFrozen(list)}></input>
		</div>
	);
}

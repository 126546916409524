import { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import { LoggedIn } from "./pages/LoggedIn/LoggedIn";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Email from "./pages/Email/Email";
import ForgottenPassword from "./pages/ForgottenPassword/ForgottenPassword";
import Login from "./pages/Login/Login";
import LoginRegister from "./pages/LoginRegister/LoginRegister";
import Register from "./pages/Register/Register";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { About } from "./pages/About/About";
import { isEmailVerified } from "./user/user";
import { AuthAction } from "./pages/AuthAction/AuthAction";
import { ConnectionStrip } from "./components/ConnectionStrip/ConnectionStrip";

export const App: FunctionComponent = () => {
	const auth = getAuth();
	const [loggedIn, setLoggedIn] = useState(!!auth.currentUser);
	const [emailVerified, setEmailVerified] = useState(isEmailVerified());

	useEffect(() => {
		return onAuthStateChanged(auth, (user) => {
			setLoggedIn(!!user);
			setEmailVerified(isEmailVerified());
		});
	}, [auth]);

	const authed = loggedIn && emailVerified;

	return (
		<>
			<ConnectionStrip></ConnectionStrip>
			<BrowserRouter>
				<Routes>
					<Route path="/login-register" element={<LoginRegister></LoginRegister>}></Route>
					<Route path="/login" element={<Login></Login>}></Route>
					<Route path="/register" element={<Register></Register>}></Route>
					<Route path="/email" element={<Email setEmailVerified={setEmailVerified}></Email>}></Route>
					<Route path="/forgotten-password" element={<ForgottenPassword></ForgottenPassword>}></Route>
					<Route
						path="/auth-action"
						element={<AuthAction setEmailVerified={setEmailVerified}></AuthAction>}></Route>
					{authed && <Route path="*" element={<LoggedIn></LoggedIn>}></Route>}
					{!authed && <Route path="/" element={<Navigate to="/login-register" />}></Route>}
					{!authed && (
						<Route path="/about" element={<About backButton={{ to: "/login-register" }}></About>}></Route>
					)}
					<Route path="*" element={<Navigate to="/login-register" replace={true} />}></Route>
				</Routes>
			</BrowserRouter>
		</>
	);
};

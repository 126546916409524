import { FunctionComponent } from "preact";
import { removeLink } from "../Item/item-model";
import { RemoveProperty } from "../Remove-Property/RemoveProperty";
import "./LinkOutput.css";

interface LinkOutputProps {
	links: {
		[key: string]: string;
	};
	itemId: string;
	editAllowed: boolean;
}

export const LinkOutput: FunctionComponent<LinkOutputProps> = ({ links, itemId, editAllowed }) => {
	return (
		<div data-styles-link-output="true" class={editAllowed ? "edit-allowed" : ""}>
			{Object.entries(links).map(([key, link]) => {
				return (
					<div key={key} className="container">
						<a href={link} class="link" target="_blank">
							{new URL(link).hostname}
						</a>
						{editAllowed && <RemoveProperty removeFunction={() => removeLink(itemId, key)} />}
					</div>
				);
			})}
		</div>
	);
};

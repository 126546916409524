import { getDatabase, off, onValue, push, ref, remove, set } from "firebase/database";
import { createItem, deleteItem, getItem, numberOfTakesLeft, releaseItem } from "../../components/Item/item-model";
import { FirebaseArray } from "../../util";
import { List } from "./List";

export interface List {
	owner: string;
	name: string;
	date: string;
	ownerCanTake: boolean;
	ownerCanSee: boolean;
	inviteesCanSee: boolean;
	invitees?: FirebaseArray;
	items?: FirebaseArray;
	frozen?: string;
}

export function createList(list: List): string {
	const { owner } = list;
	const database = getDatabase();
	const listId = push(ref(database, "lists"), list).key || "";
	set(ref(database, `users/${owner}/createdLists/${listId}`), true);
	return listId;
}

export function listOnValue(id: string, callback: (list: List) => void): () => void {
	const database = getDatabase();
	const listRef = ref(database, `lists/${id}`);
	onValue(listRef, (snapshot) => callback(snapshot.val()));
	return () => off(listRef);
}

export function getList(id: string): Promise<List> {
	const database = getDatabase();
	const listRef = ref(database, `lists/${id}`);
	return new Promise((resolve) => {
		onValue(
			listRef,
			(snapshot) => {
				resolve(snapshot.val());
			},
			{ onlyOnce: true }
		);
	});
}

export function editListProperty<T extends keyof List>(listId: string, property: T, value: NonNullable<List[T]>) {
	const database = getDatabase();
	const propertyRef = ref(database, `lists/${listId}/${property}`);
	return set(propertyRef, value);
}

export function addInvitee(listId: string, userID: string) {
	const database = getDatabase();
	const sets: Promise<void>[] = [];
	const time = new Date().getTime();
	const listsRef = ref(database, `lists/${listId}/invitees/${userID}`);
	sets.push(set(listsRef, time));
	const usersRef = ref(database, `users/${userID}/invitedLists/${listId}`);
	sets.push(set(usersRef, time));
	return sets;
}

export async function deleteInvitee(listId: string, userID: string) {
	const database = getDatabase();
	const inviteesRef = ref(database, `lists/${listId}/invitees/${userID}`);
	const invitedListsRef = ref(database, `users/${userID}/invitedLists/${listId}`);
	const list = await getList(listId);
	const releases = Object.keys(list.items || {}).map((itemID) => {
		return releaseItem(itemID, userID);
	});
	return Promise.all([remove(inviteesRef), remove(invitedListsRef), ...releases]);
}

export async function deleteList(listId: string, list?: List) {
	const database = getDatabase();

	if (!list) {
		list = await getList(listId);
	}

	await Promise.all(
		Object.keys(list.items || {}).map((id) => {
			return deleteItem(id);
		})
	);

	remove(ref(database, `users/${list.owner}/createdLists/${listId}`));
	for (const userID of Object.keys(list.invitees || {})) {
		remove(ref(database, `users/${userID}/invitedLists/${listId}`));
	}
	remove(ref(database, `lists/${listId}`));
}

export function getYourLists(userID: string, callback: (lists: FirebaseArray | null) => void): () => void {
	const database = getDatabase();
	const yourLists = ref(database, `users/${userID}/createdLists`);

	return onValue(yourLists, (snapshot) => {
		callback(snapshot.val());
	});
}

export function getInvitedLists(userID: string, callback: (lists: FirebaseArray | null) => void): () => void {
	const database = getDatabase();
	const invitedLists = ref(database, `users/${userID}/invitedLists`);

	return onValue(invitedLists, (snapshot) => {
		callback(snapshot.val());
	});
}

export async function createNewListWithNonTakenItems(list: List) {
	const newList = structuredClone(list);
	delete newList.items;
	delete newList.invitees;
	newList.date = "";
	newList.name = newList.name + " copy";
	const newListID = createList(newList);

	const itemPromises = Object.keys(list.items || {}).map(async (itemID) => {
		return getItem(itemID);
	});

	const items = await Promise.all(itemPromises);

	items.sort((a, b) => {
		return (a.order || 0) - (b.order || 0);
	});

	const nonTakenItems = items.filter((item) => {
		return numberOfTakesLeft("", item) > 0;
	});

	let i = 0;
	for (const item of nonTakenItems) {
		item.fromList = newListID;
		delete item.takenBy;
		item.order = i;
		delete item.multipleTakes;
		delete item.boughtBy;
		i++;
	}

	const createItemPromises = nonTakenItems.map((item) => {
		return createItem(item).setItem;
	});

	await Promise.all(createItemPromises);

	return newListID;
}

export function freezeList(listID: string, listDate: string) {
	const todaysDate = new Date().toISOString().slice(0, 10);

	if (listDate <= todaysDate) {
		alert("Your list can only be frozen if its date falls after the current date.");
		return;
	}

	if (
		confirm(
			"Do you want to freeze your list settings and the list date? You will not be able to change this until the list date!"
		)
	) {
		const database = getDatabase();
		const listsRef = ref(database, `lists/${listID}/frozen`);
		return set(listsRef, listDate);
	}
}

export function isFrozen(list: List): boolean {
	if (!list.frozen) return false;

	const todaysDate = new Date().toISOString().slice(0, 10);
	return list.frozen > todaysDate;
}

type Theme = "light" | "dark" | "osDefault";

declare global {
	function getTheme(): Theme;
	function initializeTheme(): void;
}

export function setTheme(theme: Theme) {
	if (theme === "osDefault") {
		localStorage.removeItem("theme");
	} else {
		localStorage.setItem("theme", theme);
	}

	initializeTheme();
}

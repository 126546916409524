import { FunctionComponent } from "preact";

export const UserRemoveIcon: FunctionComponent = () => {
	return (
		<svg viewBox="0 0 512 512" fill="currentColor">
			<path
				d="M376 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="32"
			/>
			<path
				d="M288 304c-87 0-175.3 48-191.64 138.6-2 10.92 4.21 21.4 15.65 21.4H464c11.44 0 17.62-10.48 15.65-21.4C463.3 352 375 304 288 304z"
				stroke="currentColor"
				stroke-miterlimit="10"
				stroke-width="32"
			/>
			<path
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="32"
				d="M144 232H32"
			/>
		</svg>
	);
};

import { useEffect, useState } from "preact/hooks";
import "./Register.css";
import { useNavigate } from "react-router";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { Link } from "react-router-dom";
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, updateProfile } from "firebase/auth";
import { createUser } from "../../user/user";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { connected } from "../..";

const Register = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Listo - Register";
	}, []);

	function handleInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	function handlePasswordInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		const repeatPassword = document.getElementById("repeatPassword") as HTMLInputElement;

		if (event.currentTarget.value.length >= 6) {
			event.currentTarget.setCustomValidity("");
		} else {
			event.currentTarget.setCustomValidity("Passwords must be at least 6 characters long");
		}

		if (event.currentTarget.value === repeatPassword.value) {
			repeatPassword.setCustomValidity("");
		} else {
			repeatPassword.setCustomValidity("Passwords do not match");
		}

		handleInput(event);
	}

	function handleRepeatPasswordInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.value === (document.getElementById("password") as HTMLInputElement).value) {
			event.currentTarget.setCustomValidity("");
		} else {
			event.currentTarget.setCustomValidity("Passwords do not match");
		}

		handleInput(event);
	}

	function handleBlur(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		event.currentTarget.setAttribute("data-touched", "true");
	}

	function handleSubmitClick(event: MouseEvent) {
		event.preventDefault();
		const nameInput = document.getElementById("name") as HTMLInputElement;
		const emailInput = document.getElementById("emailAddress") as HTMLInputElement;
		const passwordInput = document.getElementById("password") as HTMLInputElement;
		const repeatPasswordInput = document.getElementById("repeatPassword") as HTMLInputElement;

		nameInput?.setAttribute("data-touched", "true");
		emailInput?.setAttribute("data-touched", "true");
		passwordInput?.setAttribute("data-touched", "true");
		repeatPasswordInput?.setAttribute("data-touched", "true");

		if (document.querySelector("form")?.reportValidity()) {
			const auth = getAuth();
			setLoading(true);
			createUserWithEmailAndPassword(auth, emailInput.value, passwordInput.value)
				.then(async (userCredential) => {
					navigate("/email");
					updateProfile(userCredential.user, { displayName: nameInput.value });
					sendEmailVerification(userCredential.user);
					await createUser(userCredential.user.uid, nameInput.value);
				})
				.catch((error) => {
					let message = error.message;
					if (error.code === "auth/email-already-in-use") {
						message = "The supplied email address is already in use.";
					}
					alert(message);
					setLoading(false);
				});
		}
	}

	function handleFocus(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	return (
		<main data-register-styles="true">
			<Link to="/login-register" className="icon-button back-button">
				<ArrowIcon></ArrowIcon>
			</Link>
			<div id="content">
				<div class="headings">
					<h1>
						<ListoIcon></ListoIcon>
						<div>Listo</div>
					</h1>
					<h2>Register</h2>
				</div>
				<form>
					<div>
						<label htmlFor="name">Name</label>
						<input
							id="name"
							type="text"
							required
							autoComplete="nickname"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleInput}
							enterkeyhint="go"
						/>
					</div>
					<div>
						<label htmlFor="emailAddress">Email</label>
						<input
							id="emailAddress"
							type="email"
							required
							autoComplete="email"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleInput}
							enterkeyhint="go"
						/>
					</div>
					<div>
						<label htmlFor="password">Password</label>
						<input
							id="password"
							type="password"
							required
							autoComplete="new-password"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handlePasswordInput}
							enterkeyhint="go"
						/>
					</div>
					<div>
						<label htmlFor="repeatPassword">Re-enter Password</label>
						<input
							id="repeatPassword"
							type="password"
							required
							autoComplete="new-password"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleRepeatPasswordInput}
							enterkeyhint="go"
						/>
					</div>
					<button
						className="button"
						data-primary="true"
						onClick={handleSubmitClick}
						disabled={loading || !connected.value}>
						Register
					</button>
				</form>
			</div>
		</main>
	);
};

export default Register;

import { endAt, getDatabase, off, onValue, orderByChild, query, ref, remove, set, startAt } from "firebase/database";
import {
	EmailAuthProvider,
	FacebookAuthProvider,
	getAuth,
	GoogleAuthProvider,
	reauthenticateWithCredential,
	signInWithPopup,
	signOut,
	updateProfile,
} from "firebase/auth";
import { UserDetails } from "../pages/LoggedIn/LoggedIn";
import { getDownloadURL, getStorage, ref as storageRef, uploadBytes, deleteObject, listAll } from "firebase/storage";
import { FirebaseArray } from "../util";
import { deleteInvitee, deleteList } from "../pages/List/list-model";
import { releaseItem } from "../components/Item/item-model";
import { NavigateFunction } from "react-router";
import { removeFriend, removeFriendSourceAndTarget } from "../pages/Friends/friends-model";

export interface User {
	name: string;
	nameForSearch: string;
	invitedLists?: FirebaseArray;
	createdLists?: FirebaseArray;
	takenItems?: FirebaseArray;
	photoURL?: string;
	friends?: FirebaseArray;
	inFriendsOf?: FirebaseArray;
}

export function createUser(userID: string, name: string, photoURL = "") {
	const database = getDatabase();
	const setName = set(ref(database, `users/${userID}/name`), name);
	const setNameForSearch = set(ref(database, `users/${userID}/nameForSearch`), name.toLowerCase());
	let setPhotoURL;
	if (photoURL) {
		setPhotoURL = set(ref(database, `users/${userID}/photoURL`), photoURL);
	} else {
		setPhotoURL = Promise.resolve();
	}
	return Promise.all([setName, setNameForSearch, setPhotoURL]);
}

export function userOnValue(id: string, callback: (user: User) => void): () => void {
	const database = getDatabase();
	const userRef = ref(database, `users/${id}`);
	onValue(userRef, (snapshot) => callback(snapshot.val()));
	return () => off(userRef);
}

export function getUser(id: string): Promise<User> {
	const database = getDatabase();
	const userRef = ref(database, `users/${id}`);

	return new Promise((resolve) => {
		onValue(
			userRef,
			(snapshot) => {
				resolve(snapshot.val());
			},
			{ onlyOnce: true }
		);
	});
}

export function editUserProperty<T extends keyof User>(userId: string, property: T, value: NonNullable<User[T]>) {
	const database = getDatabase();
	const propertyRef = ref(database, `users/${userId}/${property}`);
	return set(propertyRef, value);
}

export function isAuthedsList(ownerId: string): boolean {
	const currentUserId = getAuth().currentUser?.uid || "";

	return currentUserId === ownerId;
}

export function setName(name: string, userDetails: UserDetails) {
	const newName = name.trim();
	const auth = getAuth();
	const user = auth.currentUser;
	if (!user) return;
	const currentName = user.displayName;
	if (newName === "") {
		userDetails.setName("");
		setTimeout(() => userDetails.setName(currentName!));
	} else if (newName !== currentName) {
		userDetails.setName(newName);
		updateProfile(user, { displayName: newName });
		editUserProperty(user.uid, "name", newName);
		editUserProperty(user.uid, "nameForSearch", newName.toLowerCase());
		localStorage.setItem(`${user.uid}/name`, newName);
	}
}

// If you start with a Google account you cannot login with any other provider.
// If you start with an email account you can also login with Google but not with Facebook.
// If you start with Facebook you cannot register with email but you can override it with a Google account.
// You cannot login with Facebook to non-registered accounts if your Facebook app account is in dev mode.
export function isOnlyEmailUser(): boolean {
	// Detect if only an email user, there is no Google account as well (which is possible, see above).

	const currentUser = getAuth().currentUser;
	if (!currentUser) return false;

	return currentUser.providerData[0].providerId === "password";
}
export function isGoogleUser(): boolean {
	const currentUser = getAuth().currentUser;
	if (!currentUser) return false;

	return currentUser.providerData[0].providerId === "google.com";
}
export function isFacebookUser(): boolean {
	const currentUser = getAuth().currentUser;
	if (!currentUser) return false;

	return currentUser.providerData[0].providerId === "facebook.com";
}

export function isEmailVerified(): boolean {
	return isGoogleUser() || isFacebookUser() || (isOnlyEmailUser() && !!getAuth().currentUser?.emailVerified);
}

export function setAvatar(file: File, userDetails: UserDetails) {
	const auth = getAuth();
	const storage = getStorage();
	const uid = auth.currentUser?.uid;
	const extension = file?.name.split(".").pop() || "";
	const avatarRef = storageRef(storage, `${uid}/avatar/avatar.${extension}`);

	if (file) {
		userDetails.setPhotoURL(URL.createObjectURL(file));
		uploadBytes(avatarRef, file).then((snapshot) => {
			getDownloadURL(snapshot.ref).then(async (url) => {
				if (auth.currentUser && uid) {
					updateProfile(auth.currentUser, { photoURL: url });
					editUserProperty(uid, "photoURL", url);

					// Delete the old avatar if it has not already been overwritten.
					const avatarFolderRef = storageRef(storage, `${uid}/avatar`);
					listAll(avatarFolderRef).then((res) => {
						for (const avatar of res.items) {
							if (!avatar.name.endsWith(extension)) {
								deleteObject(avatar);
							}
						}
					});

					const oldAvatarURL = localStorage.getItem(`${uid}/photoURL`);
					localStorage.setItem(`${uid}/photoURL`, url);
					caches.open("currentUser").then((cache) => {
						if (oldAvatarURL) {
							cache.delete(oldAvatarURL);
						}
						cache.put(url, new Response(file));
					});
				}
			});
		});
	}
}

export function searchForUsers(value: string, callback: (sortedLists: [string, User][]) => void) {
	const valueLower = value.toLowerCase();
	const db = getDatabase();
	const users = ref(db, "users");
	const search = query(users, orderByChild("nameForSearch"), startAt(valueLower), endAt(valueLower + "\uf8ff"));
	onValue(
		search,
		(snapshot) => {
			const results: { [key: string]: User } | null = snapshot.val();
			callback(Object.entries(results || {}));
		},
		{ onlyOnce: true }
	);
}

export function takenItemsOnValue(id: string, callback: (items: FirebaseArray | null) => void): () => void {
	const database = getDatabase();
	const usersRef = ref(database, `users/${id}/takenItems`);
	onValue(usersRef, (snapshot) => callback(snapshot.val()));
	return () => off(usersRef);
}

export async function deleteUser(userID: string) {
	const database = getDatabase();
	const user = await getUser(userID);
	for (const itemID of Object.keys(user.takenItems || {})) {
		releaseItem(itemID, userID);
	}
	for (const listID of Object.keys(user.invitedLists || {})) {
		deleteInvitee(listID, userID);
	}
	for (const listID of Object.keys(user.createdLists || {})) {
		deleteList(listID);
	}
	for (const friend of Object.keys(user.friends || {})) {
		removeFriend(friend);
	}
	for (const userIDInFriendsOf of Object.keys(user.inFriendsOf || {})) {
		removeFriendSourceAndTarget(userIDInFriendsOf, userID);
	}
	remove(ref(database, `users/${userID}`));

	// Cannot delete a folder within firebase storage :( So delete all files individually.
	const storage = getStorage();
	const avatarFolderRef = storageRef(storage, `${userID}/avatar`);

	const avatarList = await listAll(avatarFolderRef);
	const avatarDeletes = avatarList.items.map((avatar) => {
		return deleteObject(avatar);
	});
	await Promise.all(avatarDeletes);
}

export function deleteAccount(navigate: NavigateFunction) {
	const auth = getAuth();
	switch (auth.currentUser?.providerData[0]?.providerId) {
		case "google.com": {
			if (window.confirm("You need to re-enter login details to delete your account.")) {
				const provider = new GoogleAuthProvider();
				provider.addScope("profile");
				provider.addScope("email");
				provider.setCustomParameters({ login_hint: auth.currentUser.email! });
				const currentUserID = auth.currentUser?.uid;
				signInWithPopup(auth, provider)
					.then(async (credential) => {
						if (credential.user.uid !== currentUserID) {
							alert(
								"You must login with the same account to delete it. You will now be logged out of the account you logged into."
							);
							signOut(auth).finally(() => navigate("/login-register"));
							return;
						}
						await deleteUser(auth.currentUser!.uid);
						auth.currentUser?.delete().finally(() => {
							alert("Your account has been deleted");
							navigate("/login-register");
						});
					})
					.catch((error) => {
						if (error.code === "auth/popup-closed-by-user") return;
						alert(error.message);
					});
			}
			break;
		}
		case "facebook.com": {
			if (window.confirm("You need to re-enter login details to delete your account.")) {
				const provider = new FacebookAuthProvider();
				provider.addScope("user_birthday");
				provider.setCustomParameters({ login_hint: auth.currentUser.email! });
				const currentUserID = auth.currentUser?.uid;
				signInWithPopup(auth, provider)
					.then(async (credential) => {
						if (credential.user.uid !== currentUserID) {
							alert(
								"You must login with the same account to delete it. You will now be logged out of the account you logged into."
							);
							signOut(auth).finally(() => navigate("/login-register"));
							return;
						}
						await deleteUser(auth.currentUser!.uid);
						auth.currentUser?.delete().finally(() => {
							alert("Your account has been deleted");
							navigate("/login-register");
						});
					})
					.catch((error) => {
						if (error.code === "auth/popup-closed-by-user") return;
						alert(error.message);
					});
			}
			break;
		}
		case "password": {
			const password = window.prompt("Re-enter your password to delete your account.");
			const email = auth.currentUser?.email;

			if (email && password) {
				const credential = EmailAuthProvider.credential(email, password);

				reauthenticateWithCredential(auth.currentUser, credential)
					.then(async () => {
						await deleteUser(auth.currentUser!.uid);
						return auth.currentUser?.delete().finally(() => {
							alert("Your account has been deleted");
							navigate("/login-register");
						});
					})
					.catch((error) => {
						let message = error.message;
						if (error.code === "auth/wrong-password") {
							message = "Incorrect password.";
						}
						window.alert(message);
					});
			}
		}
	}
}

export function loggedInPreviously() {
	const currentUser = getAuth().currentUser?.uid;
	if (!currentUser) return false;

	return !!localStorage.getItem(`${currentUser}/loggedInPreviously`);
}

export function setLoggedInPreviously() {
	const currentUser = getAuth().currentUser?.uid;
	if (!currentUser) return false;

	localStorage.setItem(`${currentUser}/loggedInPreviously`, "true");
}

import { Unsubscribe } from "firebase/database";
import { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import { getYourLists, List, listOnValue } from "../../pages/List/list-model";
import { ListBox } from "../List-Box/ListBox";
import "./YoursPanel.css";
import { minLoading } from "../../index";

interface YoursPanelProps {
	userID: string;
	date: "past" | "future";
}

export const YoursPanel: FunctionComponent<YoursPanelProps> = ({ userID, date }) => {
	const [lists, setLists] = useState<Record<string, List>>({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let fetchedLists: string[] = [];
		const listUnsubs: Record<string, Unsubscribe> = {};
		const yourListsUnsub = getYourLists(userID, (listIDs) => {
			setLists((lists) => {
				const newLists = { ...lists };

				// Find all lists that are deleted, remove them and unsub.
				for (const listID in lists) {
					if (!listIDs?.[listID]) {
						listUnsubs[listID]();
						delete listUnsubs[listID];
						delete newLists[listID];
						fetchedLists = fetchedLists.filter((list) => {
							return list !== listID;
						});
					}
				}
				return newLists;
			});

			if (!listIDs) {
				setTimeout(() => {
					setLoading(false);
				}, minLoading);
			}
			const toLoad = Object.keys(listIDs || {}).length;
			let loaded = 0;
			for (const listID in listIDs) {
				if (fetchedLists.includes(listID)) {
					continue;
				}
				fetchedLists.push(listID);
				listUnsubs[listID] = listOnValue(listID, (list) => {
					setLists((lists) => {
						return { ...lists, ...{ [listID]: list } };
					});
					loaded++;

					setTimeout(() => {
						setLoading((loading) => {
							if (!loading) {
								return false;
							} else {
								return loaded < toLoad;
							}
						});
					}, minLoading);
				});
			}
		});
		return () => {
			yourListsUnsub();
			for (const unsub of Object.values(listUnsubs)) {
				unsub();
			}
		};
	}, [userID]);

	const listsArray = [];
	const sortedLists = Object.entries(lists)
		.filter(([_id, list]) => {
			if (!list.date) {
				return date === "future";
			}
			const listDate = new Date(list.date);
			const currentDate = new Date();
			currentDate.setHours(0, 0, 0, 0);
			if (date === "future") {
				return listDate.getTime() >= currentDate.getTime();
			} else {
				return currentDate.getTime() > listDate.getTime();
			}
		})
		.sort(([_idA, listA], [_idB, listB]) => {
			if (!listA.date) {
				return 1;
			}
			if (!listB.date) {
				return -1;
			}
			const dateA = new Date(listA.date);
			const dateB = new Date(listB.date);
			return dateA.getTime() - dateB.getTime();
		});

	for (const [id, list] of sortedLists) {
		listsArray.push(<ListBox key={id} id={id} list={list} />);
	}

	return (
		<div class="yours-panel">
			{loading && (
				<div class="message-container">
					<p>Loading...</p>
				</div>
			)}
			{!loading && listsArray.length === 0 && (
				<div class="message-container">
					<p>You have not created any lists!</p>
				</div>
			)}
			{!loading && listsArray.length > 0 && <div class="lists">{listsArray}</div>}
		</div>
	);
};

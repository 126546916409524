import { StateUpdater, useEffect, useState } from "preact/hooks";
import { useNavigate } from "react-router";
import "./Email.css";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { Link } from "react-router-dom";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { isEmailVerified } from "../../user/user";

const Email = (props: { setEmailVerified: StateUpdater<boolean> }) => {
	useEffect(() => {
		document.title = "Listo - Confirm Email";
	}, []);

	const [loading, setLoading] = useState(false);
	const currentUser = getAuth().currentUser;
	const navigate = useNavigate();

	useEffect(() => {
		if (isEmailVerified()) {
			navigate("/home");
		} else {
			if (!currentUser) {
				navigate("/login-register");
			}
		}
	}, [currentUser, navigate]);

	function verificationCheck() {
		setLoading(true);
		currentUser
			?.reload()
			.then(() => {
				if (currentUser?.emailVerified) {
					props.setEmailVerified(true);
					navigate("/home");
				} else {
					alert("Email not verified");
					setLoading(false);
				}
			})
			.catch((error) => {
				alert(error.message);
				setLoading(false);
			});
	}

	function resendEmail() {
		if (currentUser) {
			setLoading(true);
			sendEmailVerification(currentUser).finally(() => {
				setLoading(false);
			});
		}
	}

	if (isEmailVerified() || !currentUser) return <></>;

	return (
		<main data-email-styles="true">
			<Link to="/login-register" className="icon-button back-button">
				<ArrowIcon></ArrowIcon>
			</Link>
			<div id="content">
				<div class="headings">
					<h1>
						<ListoIcon></ListoIcon>
						<div>Listo</div>
					</h1>
					<h2>Confirm Email</h2>
				</div>
				<div class="other">
					<p>A verification email has been sent to {currentUser?.email}</p>
					<button className="button" disabled={loading} data-primary="true" onClick={verificationCheck}>
						Continue
					</button>
					<button className="button" disabled={loading} onClick={resendEmail}>
						Re-send Email
					</button>
				</div>
			</div>
		</main>
	);
};

export default Email;

import { getAuth } from "firebase/auth";
import { FunctionComponent } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { UserDetailsContext } from "../../pages/LoggedIn/LoggedIn";
import { User, userOnValue } from "../../user/user";
import { UserIcon } from "../icons/UserIcon";
import "./Avatar.css";

export interface AvatarProps {
	user?: User;
	userID: string;
	showName?: boolean;
	badgeText?: string;
}

export const Avatar: FunctionComponent<AvatarProps> = ({ user, userID, showName, badgeText }) => {
	const userDetails = useContext(UserDetailsContext)!;
	let url = "";
	let name = "";
	const [userState, setUserState] = useState(user);

	const isCurrentUser = userID === getAuth().currentUser?.uid;

	useEffect(() => {
		if (!user && !isCurrentUser) {
			return userOnValue(userID, (result) => {
				setUserState(result);
			});
		}
	}, [isCurrentUser, user, userID]);

	if (!userState && !isCurrentUser) {
		return <></>;
	}

	// User details are loading in.
	if (isCurrentUser && (userDetails?.photoURL === null || userDetails.name === null)) {
		return <></>;
	}

	if (isCurrentUser) {
		url = userDetails.photoURL!;
		name = userDetails.name!;
	} else {
		url = userState?.photoURL || "";
		name = userState?.name || "";
	}

	let avatar: JSX.Element;
	if (url) {
		avatar = <img src={url} alt={name}></img>;
	} else {
		avatar = <UserIcon />;
	}
	return (
		<div class="avatar">
			{avatar}
			{badgeText && <div class="badge-text">{badgeText}</div>}
			{showName && <div class="name">{name}</div>}
		</div>
	);
};

import { useEffect } from "preact/hooks";
import { iOS, PWA } from "../index";
import { notificationsOn, startNotifications } from "./notification";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./NotificationPrompt.css";
import { useSignal } from "@preact/signals";

export function NotificationPrompt() {
	const navigate = useNavigate();
	const loading = useSignal(true);
	const startingNotifications = useSignal(false);

	useEffect(() => {
		const notificationsUnavailable = iOS && !PWA;

		if (notificationsUnavailable || notificationsOn()) {
			navigate("/home");
			return;
		}
		loading.value = false;
	}, [loading, navigate]);

	function onReceiveNotificationsClick() {
		startingNotifications.value = true;
		startNotifications()
			.then(() => {
				navigate("/home");
			})
			.catch(() => {
				startingNotifications.value = false;
			});
	}

	if (loading.value) return <></>;

	return (
		<div id="notification-prompt">
			<header>
				<h1>Notifications</h1>
			</header>
			<div class="content">
				<p>
					Listo can send you a notification on this device when someone invites you to a list. You can change
					your notification settings on the user page at any time.
				</p>
				<div class="buttons">
					<button
						class="button"
						data-primary
						onClick={onReceiveNotificationsClick}
						disabled={startingNotifications.value}>
						Receive Notifications
					</button>
					<Link to={"/home"} class="button">
						Continue
					</Link>
				</div>
			</div>
		</div>
	);
}

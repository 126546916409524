import { useSearchParams } from "react-router-dom";
import { VerifyEmail } from "../VerifyEmail/VerifyEmail";
import { StateUpdater } from "preact/hooks";
import PasswordReset from "../PasswordReset/PasswordReset";

export function AuthAction({ setEmailVerified }: { setEmailVerified: StateUpdater<boolean> }) {
	const [searchParams] = useSearchParams();

	const mode = searchParams.get("mode");
	const code = searchParams.get("oobCode")!;

	if (mode === "verifyEmail") {
		return <VerifyEmail setEmailVerified={setEmailVerified} code={code} />;
	} else {
		return <PasswordReset code={code} />;
	}
}

import { useEffect, useState } from "preact/hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Avatar } from "../../components/Avatar/Avatar";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { UserAddIcon } from "../../components/icons/UserAddIcon";
import { UserRemoveIcon } from "../../components/icons/UserRemoveIcon";
import { getUser, User } from "../../user/user";
import { addInvitee, deleteInvitee, listOnValue } from "../List/list-model";
import "./AddInvitee.css";
import { friendsOnValue, Friends } from "../Friends/friends-model";
import { useSignal } from "@preact/signals";
import { minLoading } from "../../index";
import { setInPageNavigation } from "../Friends/AddFriends/AddFriends";

type RouteParams = {
	listId: string;
};

export function AddInvitee() {
	const { listId = "" } = useParams<RouteParams>();
	const friendIDs = useSignal<Friends>({});
	const friends = useSignal<{ [userID: string]: User }>({});
	const [loadedFriends, setLoadedFriends] = useState(false);
	const [loadedList, setLoadedList] = useState(false);
	const [invitees, setInvitees] = useState<string[]>([]);
	const navigate = useNavigate();

	useEffect(() => {
		return listOnValue(listId, (list) => {
			if (!list) {
				navigate("/home");
				return;
			}
			setLoadedList(true);
			const invitees = Object.keys(list.invitees || []);
			setInvitees(invitees);
			document.title = `Listo - ${list?.name || ""}`;
		});
	}, [listId, navigate]);

	useEffect(() => {
		friendsOnValue((value) => {
			const newFriends: { [userID: string]: User } = {};
			const userCalls: Promise<[string, User]>[] = [];
			for (const id in value) {
				if (id in friends) {
					newFriends[id] = friends.value[id];
				} else {
					userCalls.push(
						getUser(id).then((user) => {
							return [id, user];
						})
					);
				}
			}
			Promise.all(userCalls).then((result) => {
				for (const [id, user] of result) {
					newFriends[id] = user;
				}
				friendIDs.value = value || {};
				friends.value = newFriends;
				setTimeout(() => {
					setLoadedFriends(true);
				}, minLoading);
			});
		});
	}, [friendIDs, friends]);

	if (!loadedList) return <></>;

	return (
		<div class="add-invitee">
			<header>
				<Link to={`/list/${listId}#invited`} className="icon-button back">
					<ArrowIcon></ArrowIcon>
				</Link>
				<h1>Invite Friends to Your List</h1>
			</header>
			<div class="content">
				{!loadedFriends && (
					<div class="message-container">
						<div>
							<p>Loading...</p>
						</div>
					</div>
				)}
				{Object.keys(friends.value).length == 0 && loadedFriends && (
					<div class="message-container">
						<div>
							<p>You have no friends yet!</p>
							<Link
								to="/add-friends"
								onClick={() => {
									setInPageNavigation(true);
								}}
								className="button"
								data-primary="true"
								state={{ backDestination: location.pathname }}>
								Add Friends
							</Link>
						</div>
					</div>
				)}
				{loadedFriends && Object.keys(friends.value).length > 0 && (
					<div class="friends">
						{Object.entries(friends.value)
							.sort(([userIdA], [userIdB]) => {
								return friendIDs.value[userIdA] - friendIDs.value[userIdB];
							})
							.map(([userID, user]) => {
								return (
									<div class="friend">
										<div class="avatar-and-name">
											<div class="avatar-container">
												<Avatar userID={userID} user={user}></Avatar>
											</div>
											<h2>{user.name}</h2>
										</div>
										{invitees.includes(userID) && (
											<button
												class="icon-button remove-invitee"
												onClick={() => deleteInvitee(listId, userID)}>
												<UserRemoveIcon></UserRemoveIcon>
											</button>
										)}
										{!invitees.includes(userID) && (
											<button
												class="icon-button add-invitee-button"
												onClick={() => addInvitee(listId, userID)}>
												<UserAddIcon></UserAddIcon>
											</button>
										)}
									</div>
								);
							})}
					</div>
				)}
			</div>
		</div>
	);
}

import { FunctionComponent } from "preact";
import { TrashIcon } from "../icons/TrashIcon";

interface RemovePropertyProps {
	removeFunction: () => void;
}

export const RemoveProperty: FunctionComponent<RemovePropertyProps> = ({ removeFunction }) => {
	return (
		<button className="icon-button remove-property" onClick={() => removeFunction()}>
			<TrashIcon />
		</button>
	);
};

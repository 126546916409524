import { getAuth } from "firebase/auth";
import { getDatabase, onValue, ref, remove, set } from "firebase/database";

export interface Friends {
	[userID: string]: number;
}

export function addFriend(userID: string) {
	const database = getDatabase();
	const currentUser = getAuth().currentUser?.uid;
	if (!currentUser) return;
	const friendsRef = ref(database, `users/${currentUser}/friends/${userID}`);
	const inFriendsOf = ref(database, `users/${userID}/inFriendsOf/${currentUser}`);
	const timestamp = new Date().getTime();
	const friendsSet = set(friendsRef, timestamp);
	const inFriendsOfSet = set(inFriendsOf, timestamp);
	return Promise.all([friendsSet, inFriendsOfSet]);
}

export function removeFriend(userID: string) {
	const database = getDatabase();
	const currentUser = getAuth().currentUser?.uid;
	if (!currentUser) return;
	const friendsRef = ref(database, `users/${currentUser}/friends/${userID}`);
	const inFriendsOf = ref(database, `users/${userID}/inFriendsOf/${currentUser}`);
	return Promise.all([remove(friendsRef), remove(inFriendsOf)]);
}

export function removeFriendSourceAndTarget(sourceUserID: string, targetUserID: string) {
	const database = getDatabase();
	const friendsRef = ref(database, `users/${sourceUserID}/friends/${targetUserID}`);
	const inFriendsOf = ref(database, `users/${targetUserID}/inFriendsOf/${sourceUserID}`);
	return Promise.all([remove(friendsRef), remove(inFriendsOf)]);
}

export function friendsOnValue(callback: (user: Friends | null) => void) {
	const database = getDatabase();
	const currentUser = getAuth().currentUser?.uid;
	const friendsRef = ref(database, `users/${currentUser}/friends`);
	return onValue(friendsRef, (snapshot) => callback(snapshot.val()));
}

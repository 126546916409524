import { FunctionComponent } from "preact";
import { useRef, useEffect } from "preact/hooks";
import { AddIcon } from "../icons/AddIcon";
import { addLink, editProperty, Item } from "../Item/item-model";
import "./PropertySelector.css";

interface PropertySelectorProps {
	itemId: string;
	item: Item;
}

export const PropertySelector: FunctionComponent<PropertySelectorProps> = ({ itemId, item }) => {
	const selectEl = useRef<HTMLSelectElement>(null);

	useEffect(() => {
		if (selectEl.current) {
			selectEl.current.value = "";
		}
	}, []);

	function selectChange(event: Event) {
		if (!(event.target instanceof HTMLSelectElement)) {
			return;
		}

		const selectedProperty = event.target.value;

		switch (selectedProperty) {
			case "price":
				editProperty(itemId, "price", "10");
				break;
			case "allowMultiple":
				editProperty(itemId, selectedProperty, true);
				break;
			case "allowMultipleWithLimit":
				editProperty(itemId, selectedProperty, 3);
				break;
			case "links": {
				const url = prompt("Type or paste in a link");
				if (url) {
					try {
						const urlObject = new URL(url);
						if (urlObject.protocol !== "https:") {
							throw Error();
						}
						addLink(itemId, urlObject.toString());
					} catch (e) {
						alert("You did not enter a valid link");
					}
				}
				break;
			}
			case "description": {
				editProperty(itemId, "description", "Description");
				break;
			}
			case "priority": {
				editProperty(itemId, "priority", "high");
			}
		}
		event.target.value = "";
	}

	return (
		<div data-styles-property-selector="true" className="button">
			<AddIcon />
			<select ref={selectEl} onChange={selectChange}>
				<option value="price" disabled={item.price !== undefined}>
					Price
				</option>
				<option value="links">Link</option>
				<option value="description" disabled={!!item.description}>
					Description
				</option>
				<option value="priority" disabled={!!item.priority}>
					Priority
				</option>

				<optgroup label="Allow Multiple">
					<option value="allowMultiple" disabled={!!item.allowMultiple || !!item.allowMultipleWithLimit}>
						No Limit
					</option>
					<option
						value="allowMultipleWithLimit"
						disabled={!!item.allowMultiple || !!item.allowMultipleWithLimit}>
						Limit
					</option>
				</optgroup>
			</select>
		</div>
	);
};

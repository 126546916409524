import { FunctionComponent } from "preact";
import { useState, useEffect, useRef } from "preact/hooks";
import { useSearchParams } from "react-router-dom";
import { Item } from "../Item/Item";
import "./ItemsPanel.css";
import { List } from "../../pages/List/list-model";
import { android, minLoading } from "../../index";
import { Unsubscribe } from "firebase/database";
import { Item as ItemModel, editProperty, isHidden, itemOnValue } from "../Item/item-model";
import { getAuth } from "firebase/auth";

export interface ItemsPanelProps {
	list: List;
	sortOrder: "default" | "priority" | "priceLowToHigh" | "priceHighToLow";
}

export const ItemsPanel: FunctionComponent<ItemsPanelProps> = ({ list, sortOrder }) => {
	const [items, setItems] = useState<{ [itemID: string]: ItemModel }>({});
	const [searchParams] = useSearchParams();
	const itemToScrollTo = searchParams.get("item");
	const [loading, setLoading] = useState(true);
	const fetchedItems = useRef<string[]>([]);
	const itemUnsubs = useRef<{ [itemID: string]: Unsubscribe }>({});
	const firstLoad = useRef<boolean>(true);
	const [dragStart, setDragStart] = useState<DragEvent | null>(null);
	const [dropIndicatorOrder, setDropIndicatorOrder] = useState<number | null>(null);

	// Catches the edge case where a non-owner is viewing the list and we need to sort because of item changes.
	const [doASort, setDoASort] = useState(0);

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			for (const unsub of Object.values(itemUnsubs.current)) {
				unsub();
			}
		};
	}, []);

	// Needed for the case where you create a list with non-taken items and then browser back/forward.
	useEffect(() => {
		for (const itemID of Object.keys(items)) {
			if (itemID in (list.items || {})) {
				continue;
			}

			setItems((items) => {
				delete items[itemID];
				return { ...items };
			});
			itemUnsubs.current[itemID]();
			delete itemUnsubs.current[itemID];
			fetchedItems.current = fetchedItems.current.filter((item) => {
				return item !== itemID;
			});
		}
	}, [list.items, items]);

	useEffect(() => {
		setItems((oldItems) => {
			let sortedItems: [string, ItemModel][] = [];
			switch (sortOrder) {
				case "priority":
					{
						sortedItems = Object.entries(oldItems).sort((a, b) => {
							const [_idA, itemA] = a;
							const [_idB, itemB] = b;

							if (itemA.priority && !itemB.priority) {
								return -1;
							}

							if (itemB.priority && !itemA.priority) {
								return 1;
							}

							return (itemA.order || 0) - (itemB.order || 0);
						});
					}
					break;
				case "default": {
					sortedItems = Object.entries(oldItems).sort((a, b) => {
						const [_idA, itemA] = a;
						const [_idB, itemB] = b;

						const aOrder = itemA.order || 0;
						const bOrder = itemB.order || 0;
						return aOrder - bOrder;
					});
					break;
				}
				case "priceHighToLow": {
					sortedItems = Object.entries(oldItems).sort((a, b) => {
						const [_idA, itemA] = a;
						const [_idB, itemB] = b;

						if (itemA.price === undefined && itemB.price === undefined) {
							return (itemA.order || 0) - (itemB.order || 0);
						}

						let aPrice = itemA.price || 0;
						if (typeof aPrice === "string") {
							aPrice = parseInt(aPrice);
						}
						let bPrice = itemB.price || 0;
						if (typeof bPrice === "string") {
							bPrice = parseInt(bPrice);
						}

						return bPrice - aPrice;
					});
					break;
				}
				case "priceLowToHigh": {
					sortedItems = Object.entries(oldItems).sort((a, b) => {
						const [_idA, itemA] = a;
						const [_idB, itemB] = b;

						if (itemA.price === undefined && itemB.price === undefined) {
							return (itemA.order || 0) - (itemB.order || 0);
						}

						let aPrice = itemA.price || 0;
						if (typeof aPrice === "string") {
							aPrice = parseInt(aPrice);
						}
						let bPrice = itemB.price || 0;
						if (typeof bPrice === "string") {
							bPrice = parseInt(bPrice);
						}
						return aPrice - bPrice;
					});
					break;
				}
				default:
					break;
			}
			sortedItems.forEach(([id, item], index) => {
				if (list.owner === getAuth().currentUser?.uid) {
					item.order = index;
					item.clientOrder = index;
					editProperty(id, "order", index);
				} else {
					item.clientOrder = index;
				}
			});

			return Object.fromEntries(sortedItems);
		});
	}, [list, doASort, sortOrder]);

	useEffect(() => {
		if (!list.items) {
			setTimeout(() => {
				setLoading(false);
				firstLoad.current = false;
			}, minLoading);
			return;
		}

		let toLoad: number | null = null;
		let loaded: number | null = null;
		if (firstLoad.current) {
			toLoad = Object.keys(list.items || {}).length;
			loaded = 0;
		}
		for (const itemID in list.items) {
			if (fetchedItems.current.includes(itemID)) {
				continue;
			}
			fetchedItems.current.push(itemID);
			let itemFirstLoad = true;
			itemUnsubs.current[itemID] = itemOnValue(itemID, (item) => {
				if (item === null) {
					setItems((items) => {
						delete items[itemID];
						return { ...items };
					});
					itemUnsubs.current[itemID]();
					delete itemUnsubs.current[itemID];
					fetchedItems.current = fetchedItems.current.filter((item) => {
						return item !== itemID;
					});
				} else {
					setItems((items) => {
						if (item.order === undefined) {
							item.order = Object.keys(list.items || {}).indexOf(itemID);
						}

						const clientOrder = items[itemID]?.clientOrder;
						if (clientOrder === undefined) {
							item.clientOrder = item.order;
						} else {
							item.clientOrder = clientOrder;
						}
						return { ...items, ...{ [itemID]: item } };
					});
					if (!firstLoad.current) {
						setDoASort((value) => value + 1);
					}
					if (itemFirstLoad && firstLoad.current && loaded !== null && toLoad !== null) {
						itemFirstLoad = false;
						loaded++;
						if (loaded >= toLoad) {
							firstLoad.current = false;
							setTimeout(() => {
								setLoading(false);
							}, minLoading);
						}
					}
				}
			});
		}
	}, [list, sortOrder]);

	useEffect(() => {
		if (!loading && itemToScrollTo) {
			const elementToScrollTo = document.getElementById(itemToScrollTo);
			const elementToScroll = document.getElementById("items");
			elementToScroll?.scroll({
				top: elementToScrollTo?.offsetTop,
				behavior: "smooth",
			});
		}
	}, [itemToScrollTo, loading]);

	const itemsArray: JSX.Element[] = [];
	for (const [id, item] of Object.entries(items)) {
		if (isHidden(list, item)) {
			continue;
		}

		itemsArray.push(
			<div id={id} key={id} class="item-container">
				<Item id={id} item={item} editAllowed={true} list={list} />
			</div>
		);
	}

	itemsArray.sort((a, b) => {
		const aOrder = items[a.key].clientOrder || 0;
		const bOrder = items[b.key].clientOrder || 0;
		return aOrder - bOrder;
	});

	if (dropIndicatorOrder !== null) {
		itemsArray.splice(
			dropIndicatorOrder,
			0,
			<div class="drop-indicator" style={`grid-row-start: ${dropIndicatorOrder + 1}`}></div>
		);
	}

	function onDragStart(event: DragEvent) {
		if (!(event.target instanceof HTMLElement)) return;

		setDragStart(event);
		event.dataTransfer!.setData("text/plain", android ? "neededForAndroid" : "");
		event.dataTransfer!.effectAllowed = "move";
	}

	function onDragOver(event: DragEvent) {
		event.preventDefault();
		event.dataTransfer!.dropEffect = "move";

		if (!(event.target instanceof HTMLElement) || !dragStart) {
			return;
		}

		if (event.target.classList.contains("items-container")) {
			// We can only drop on the very start or end of the items container.
			const itemsContainerPadding = 8;
			if (event.offsetY <= itemsContainerPadding) {
				setDropIndicatorOrder(0);
			} else {
				const itemsArrayFiltered = itemsArray.filter((item) => {
					return item.props.class === "item-container";
				});
				setDropIndicatorOrder(itemsArrayFiltered.length);
			}
			return;
		}

		const draggedOverItem = event.target.closest(".item-container");
		if (!draggedOverItem) {
			return;
		}

		const draggedOverItemOrder = getItemOrder(draggedOverItem.id);
		let eventOffset = event.offsetY;
		if (!event.target.classList.contains("item-container")) {
			eventOffset =
				eventOffset + (event.target.getBoundingClientRect().top - draggedOverItem.getBoundingClientRect().top);
		}

		const threshold = draggedOverItem.clientHeight / 2;

		let dropIndicatorOrder: number | null = null;
		if (eventOffset >= threshold) {
			dropIndicatorOrder = draggedOverItemOrder + 1;
		} else {
			dropIndicatorOrder = draggedOverItemOrder;
		}

		setDropIndicatorOrder(dropIndicatorOrder);
	}

	function onDrop(event: DragEvent) {
		event.stopPropagation(); // Stops some browsers redirecting.
		if (!(event.target instanceof HTMLElement) || !dragStart) return;

		const draggedItem = (dragStart.target as HTMLElement).parentElement!;
		const droppedOnItem = event.target.closest(".item-container");
		const draggedItemOrder = getItemOrder(draggedItem.id);
		let droppedOnItemOrder: number | null = null;
		if (droppedOnItem) {
			droppedOnItemOrder = getItemOrder(droppedOnItem.id);
		}

		let newDraggedItemOrder: number;
		if (event.target.classList.contains("items-container")) {
			// We can only drop on the very start or end of the items container.
			const itemsContainerPadding = 8;
			if (event.offsetY <= itemsContainerPadding) {
				newDraggedItemOrder = 0;
			} else {
				const itemsArrayFiltered = itemsArray.filter((item) => {
					return item.props.class === "item-container";
				});
				newDraggedItemOrder = itemsArrayFiltered.length - 1;
			}
		} else {
			if (
				!droppedOnItem ||
				droppedOnItemOrder === null ||
				!droppedOnItem?.parentNode ||
				draggedItem === droppedOnItem
			) {
				return;
			}

			let eventOffset = event.offsetY;
			if (!event.target.classList.contains("item-container")) {
				eventOffset =
					eventOffset +
					(event.target.getBoundingClientRect().top - droppedOnItem.getBoundingClientRect().top);
			}

			const threshold = droppedOnItem.clientHeight / 2;

			let placement: "beforeDroppedItem" | "afterDroppedItem";
			if (eventOffset > threshold) {
				placement = "afterDroppedItem";
			} else {
				placement = "beforeDroppedItem";
			}

			if (draggedItemOrder > droppedOnItemOrder) {
				// Dragging upwards.
				if (placement === "beforeDroppedItem") {
					newDraggedItemOrder = droppedOnItemOrder;
				} else {
					newDraggedItemOrder = droppedOnItemOrder + 1;
				}
			} else {
				// Dragging downwards.
				if (placement === "beforeDroppedItem") {
					newDraggedItemOrder = droppedOnItemOrder - 1;
				} else {
					newDraggedItemOrder = droppedOnItemOrder;
				}
			}
		}

		for (const [itemID, item] of Object.entries(items)) {
			const noOrder = item.order === undefined;
			const order = getItemOrder(itemID);

			// If the re-order was above me.
			if (droppedOnItemOrder !== null && order < draggedItemOrder && order < droppedOnItemOrder) {
				if (noOrder) {
					item.clientOrder = order;
					editProperty(itemID, "order", order);
				}
				continue;
			}

			// If the re-order was below me.
			if (droppedOnItemOrder !== null && order > draggedItemOrder && order > droppedOnItemOrder) {
				if (noOrder) {
					item.clientOrder = order;
					editProperty(itemID, "order", order);
				}
				continue;
			}

			// If I am the dragged item.
			if (itemID === draggedItem.id) {
				item.clientOrder = newDraggedItemOrder;
				editProperty(itemID, "order", newDraggedItemOrder);
				continue;
			}

			// If the dragged item was above me but is now below me.
			if (draggedItemOrder > order && newDraggedItemOrder <= order) {
				item.clientOrder = order + 1;
				editProperty(itemID, "order", order + 1);
				continue;
			}

			// If the dragged item was below me and is now above me.
			if (draggedItemOrder < order && newDraggedItemOrder >= order) {
				item.clientOrder = order - 1;
				editProperty(itemID, "order", order - 1);
				continue;
			}
		}
	}

	function onDragEnd() {
		setDropIndicatorOrder(null);
	}

	function onDragLeave(event: DragEvent) {
		if (
			(event.relatedTarget instanceof HTMLElement || event.relatedTarget instanceof SVGElement) &&
			!event.relatedTarget.closest(".items-container")
		) {
			setDropIndicatorOrder(null);
		}
	}

	function getItemOrder(id: string) {
		const order = items[id].order;
		if (order !== undefined) {
			return order;
		}

		const itemsArrayFiltered = itemsArray.filter((item) => {
			return item.props.class === "item-container";
		});

		return itemsArrayFiltered.findIndex((item) => {
			return id === item.key;
		});
	}

	return (
		<div data-styles-items-panel="true">
			{loading && (
				<div class="message-container">
					<p>Loading...</p>
				</div>
			)}
			{itemsArray.length === 0 && !loading && (
				<div class="message-container">
					<p>There are no items on this list!</p>
				</div>
			)}
			{!loading && itemsArray.length > 0 && (
				<div
					class="items-container"
					onDragStart={onDragStart}
					onDragOver={onDragOver}
					onDrop={onDrop}
					onDragEnd={onDragEnd}
					onDragLeave={onDragLeave}>
					{itemsArray}
				</div>
			)}
		</div>
	);
};

import { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import "./ForgottenPassword.css";
import { useNavigate } from "react-router";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { Link } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { connected } from "../..";

const ForgottenPassword: FunctionComponent = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Listo - Forgotten Password";
	}, []);

	function handleInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	function handleBlur(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		event.currentTarget.setAttribute("data-touched", "true");
	}

	function handleFocus(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	function handleSendCodeClick(event: MouseEvent) {
		event.preventDefault();
		const emailInput = document.getElementById("emailAddress") as HTMLInputElement;
		const auth = getAuth();

		emailInput?.setAttribute("data-touched", "true");

		if (document.querySelector("form")?.reportValidity()) {
			setLoading(true);
			sendPasswordResetEmail(auth, emailInput.value)
				.then(() => {
					alert(`A password reset email has been sent to ${emailInput.value}.`);
					navigate("/login");
				})
				.catch((error) => {
					if (error.code === "auth/user-not-found") {
						error.message = "There is no account registered at the provided email.";
					}
					alert(error.message);
					setLoading(false);
				});
		}
	}

	return (
		<main data-forgotten-password-styles="true">
			<Link to="/login" className="icon-button back-button">
				<ArrowIcon></ArrowIcon>
			</Link>
			<div id="content">
				<div class="headings">
					<h1>
						<ListoIcon></ListoIcon>
						<div>Listo</div>
					</h1>
					<h2>Forgotten Your Password?</h2>
				</div>
				<div class="other">
					<p>We will send you a link to help you reset your password</p>
					<form>
						<div>
							<label htmlFor="emailAddress">Email</label>
							<input
								id="emailAddress"
								type="email"
								required
								enterkeyhint="send"
								autoComplete="email"
								onBlur={handleBlur}
								onFocus={handleFocus}
								onInput={handleInput}
							/>
						</div>
						<button
							className="button"
							data-primary="true"
							onClick={handleSendCodeClick}
							disabled={loading || !connected.value}>
							Send Link
						</button>
					</form>
				</div>
			</div>
		</main>
	);
};

export default ForgottenPassword;

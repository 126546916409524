import { getAuth } from "firebase/auth";
import { FunctionComponent } from "preact";
import { useState, useEffect } from "preact/hooks";
import { deleteInvitee } from "../../pages/List/list-model";
import { User, userOnValue } from "../../user/user";
import { Avatar } from "../Avatar/Avatar";
import { UserRemoveIcon } from "../icons/UserRemoveIcon";
import "./Invitee.css";

interface InviteeProps {
	listID: string;
	userID: string;
	onLoad?: () => void;
	isCurrentUsersList: boolean;
}

export const Invitee: FunctionComponent<InviteeProps> = ({ listID, userID, onLoad, isCurrentUsersList }) => {
	const [invitee, setInvitee] = useState<User>();

	const isCurrentUserTheInvitee = userID === getAuth().currentUser?.uid;

	useEffect(() => {
		let loaded = false;
		return userOnValue(userID, (user) => {
			if (!loaded) {
				onLoad?.();
				loaded = true;
			}
			setInvitee(user);
		});
	}, [userID, onLoad]);

	function onRemoveClick() {
		if (isCurrentUserTheInvitee) {
			if (confirm("Are you sure you want to remove yourself from this list?")) {
				deleteInvitee(listID, userID);
			}
		} else {
			deleteInvitee(listID, userID);
		}
	}

	return (
		<div class="invitee">
			<div class="avatar-and-name">
				{invitee && <Avatar userID={userID} user={invitee}></Avatar>}
				<p>{invitee?.name}</p>
			</div>
			{(isCurrentUsersList || isCurrentUserTheInvitee) && (
				<button class="icon-button remove-invitee" onClick={onRemoveClick}>
					<UserRemoveIcon></UserRemoveIcon>
				</button>
			)}
		</div>
	);
};

import { FunctionComponent } from "preact";
import { useState, useCallback } from "preact/hooks";
import { FirebaseArray } from "../../util";
import { Invitee } from "../Invitee/Invitee";
import "./InviteesPanel.css";

interface InviteesPanelProps {
	listID: string;
	invitees: FirebaseArray;
	isCurrentUsersList: boolean;
}

export const InviteesPanel: FunctionComponent<InviteesPanelProps> = ({ listID, invitees, isCurrentUsersList }) => {
	const [inviteesLoaded, setInviteesLoaded] = useState(0);
	const [inviteesToLoad] = useState(Object.keys(invitees).length);

	const onLoad = useCallback(() => {
		setInviteesLoaded((loaded) => loaded + 1);
	}, []);

	const sortedInvitees = Object.entries(invitees)
		.sort(([_idA, timeA], [_idB, timeB]) => {
			return timeA - timeB;
		})
		.map(([id]) => {
			return id;
		});

	const inviteesArray: JSX.Element[] = [];
	for (const id of sortedInvitees) {
		inviteesArray.push(
			<Invitee key={id} listID={listID} userID={id} onLoad={onLoad} isCurrentUsersList={isCurrentUsersList} />
		);
	}

	const waitingForInvitees = inviteesArray.length > 0 && inviteesLoaded < inviteesToLoad;

	return (
		<div data-styles-invitees-panel="true">
			{waitingForInvitees && (
				<div className="message-container">
					{/* <p>Not going to bother with loading as coming from add invitees flashes it and passing data between routes is trickier...</p> */}
				</div>
			)}
			{inviteesArray.length === 0 && (
				<div className="message-container">
					<p>Noone has been invited to this list!</p>
				</div>
			)}
			{inviteesArray.length > 0 && (
				<div style={waitingForInvitees ? "display: none" : ""} class="invitees-container">
					{inviteesArray}
				</div>
			)}
		</div>
	);
};

export function RemoveIcon() {
	return (
		<svg viewBox="0 0 512 512">
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="32"
				d="M400 256H112"
			/>
		</svg>
	);
}

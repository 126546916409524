import { getAuth } from "firebase/auth";
import { FunctionComponent } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { Link } from "react-router-dom";
import { Avatar } from "../Avatar/Avatar";
import { EditableInput } from "../Editable-Input/EditableInput";
import { TrashIcon } from "../icons/TrashIcon";
import { LinkOutput } from "../Link-Output/LinkOutput";
import { PropertySelector } from "../Property-Selector/PropertySelector";
import { RemoveProperty } from "../Remove-Property/RemoveProperty";
import {
	deleteItem,
	Item as ItemModel,
	editProperty,
	takeItem,
	releaseItem,
	numberOfTakesLeft,
	changeTakeCount,
	numberOfTakes,
} from "./item-model";
import "./Item.css";
import { List } from "../../pages/List/list-model";
import { AddIcon } from "../icons/AddIcon";
import { RemoveIcon } from "../icons/RemoveIcon";
import { SimpleMenuIcon } from "../icons/SimpleMenuIcon";

interface ItemProps {
	id: string;
	nameIsALink?: boolean;
	editAllowed: boolean;
	item: ItemModel;
	list: List;
}

export const Item: FunctionComponent<ItemProps> = ({
	id,
	nameIsALink = false,
	editAllowed,
	list: listProp,
	item: itemProp,
}) => {
	const [item, setItem] = useState(itemProp);
	const [list, setList] = useState(listProp);
	const takenByContainer = useRef<HTMLDivElement>(null);
	const currentUserID = getAuth().currentUser?.uid || "";
	const takenByCurrentUser = !!item?.takenBy?.[currentUserID];
	const takeMenuSelect = useRef<HTMLSelectElement>(null);

	function onDeleteClick() {
		if (!item) return;

		if (confirm("Are you sure you want to delete " + item.name + "?")) {
			deleteItem(id, item);
		}
	}

	useEffect(() => {
		setList(listProp);
	}, [listProp]);

	useEffect(() => {
		setItem(itemProp);
	}, [itemProp]);

	useEffect(() => {
		if (takeMenuSelect.current) {
			takeMenuSelect.current.value = "";
		}
	}, []);

	function removeProperty(property: keyof ItemModel) {
		editProperty(id, property, null);
	}

	function onBoughtRemove() {
		const { boughtBy = {} } = item;
		delete boughtBy[currentUserID];
		editProperty(id, "boughtBy", boughtBy);
	}

	function updatePrice(value: string) {
		if (value === "") {
			editProperty(id, "price", null);
		} else {
			editProperty(id, "price", value);
		}
	}

	function updateName(value: string) {
		if (value === "") {
			const name = item?.name || "broken";
			setItem({ ...item, name: "" } as ItemModel);
			setTimeout(() => setItem({ ...item, name } as ItemModel));
		} else {
			editProperty(id, "name", value);
		}
	}

	function updateDescription(value: string) {
		if (value === "") {
			editProperty(id, "description", null);
		} else {
			editProperty(id, "description", value);
		}
	}

	async function onTakeClick() {
		if (takenByCurrentUser) {
			releaseItem(id, currentUserID);
		} else {
			await takeItem(id, currentUserID);
			takenByContainer.current?.scroll({ left: takenByContainer.current.scrollWidth, behavior: "smooth" });
			if (takeMenuSelect.current) {
				takeMenuSelect.current.value = "";
			}
		}
	}

	function onMinusClick() {
		const allowMultipleWithLimit = item.allowMultipleWithLimit! - 1;
		editProperty(id, "allowMultipleWithLimit", allowMultipleWithLimit);
	}

	function onAddClick() {
		const allowMultipleWithLimit = item.allowMultipleWithLimit! + 1;
		editProperty(id, "allowMultipleWithLimit", allowMultipleWithLimit);
	}

	const takes = Object.entries(item?.takenBy || {})
		.sort(([_idA, timeA], [_idB, timeB]) => {
			return timeA - timeB;
		})
		.map(([id]) => {
			return id;
		});

	function onTakeMenuChange(event: Event) {
		if (!(event.target instanceof HTMLSelectElement)) {
			return;
		}

		switch (event.target.value) {
			case "bought": {
				const { boughtBy: bought } = item;
				editProperty(id, "boughtBy", { ...bought, [currentUserID]: new Date().getTime() });
				break;
			}
			case "numberTaken": {
				const takesString = prompt("How many times would you like to take the item?");
				if (takesString === null) {
					break;
				}
				const takesNumber = parseInt(takesString);
				if (Number.isNaN(takesNumber)) {
					alert("You did not enter a valid number.");
					break;
				}

				if (takesNumber < 1) {
					alert("A number greater than zero must be entered.");
					break;
				}

				const takesLeft = numberOfTakesLeft(currentUserID, item);
				if (takesNumber > takesLeft) {
					alert("You have exceeded the limit.");
					break;
				}

				changeTakeCount({ userID: currentUserID, item, itemID: id, takes: takesNumber });
				break;
			}
			default:
				break;
		}

		event.target.value = "";
	}

	function currentUserBought() {
		return Object.keys(item.boughtBy || {}).includes(currentUserID);
	}

	const listURL = new URL(`list/${item.fromList}`, location.origin);
	listURL.searchParams.set("item", id);

	const isYourList = list.owner === currentUserID;
	const currentUserTaken = !!item.takenBy?.[currentUserID];
	const actuallyEditAllowed = editAllowed && isYourList;
	const takers = Object.keys(item.takenBy || {}).length;
	const takenByAnotherUser = !currentUserTaken && takers;
	const yourInvited = Object.keys(list.invitees || {}).includes(currentUserID);
	const itemAllowing =
		!takenByAnotherUser ||
		item.allowMultiple ||
		(item.allowMultipleWithLimit && numberOfTakes(item) < item.allowMultipleWithLimit);
	const canTake = currentUserTaken || ((yourInvited || (isYourList && list.ownerCanTake)) && itemAllowing);
	const canSeeTakenBy = (isYourList && list.ownerCanSee) || (!isYourList && list.inviteesCanSee);

	return (
		<div
			draggable={actuallyEditAllowed}
			data-item-styles="true"
			class={actuallyEditAllowed ? "edit-allowed" : ""}
			style={{
				gridTemplateColumns: `${actuallyEditAllowed ? "max-content" : ""} 1fr ${canTake ? "max-content" : ""}`,
			}}>
			{actuallyEditAllowed && (
				<div className="item-management">
					{<PropertySelector itemId={id} item={item} />}
					<button className="button" onClick={onDeleteClick}>
						<TrashIcon />
					</button>
				</div>
			)}
			<div className="item-content">
				<div class="heading-and-taken-by">
					<h2>
						{nameIsALink && (
							<Link className="link name-link" to={listURL.toString()}>
								{item.name}
							</Link>
						)}
						{!nameIsALink && (
							<EditableInput
								type="text"
								value={item.name}
								onChange={updateName}
								editAllowed={actuallyEditAllowed}
							/>
						)}
					</h2>
					<div ref={takenByContainer} className="taken-by">
						{takes
							.filter((taker) => {
								if (canSeeTakenBy) {
									return true;
								} else {
									return taker === currentUserID;
								}
							})
							.map((taker) => {
								let badgeText = "";
								const multipleTakes = item.multipleTakes?.[taker];
								if (multipleTakes) {
									badgeText = multipleTakes.toString();
								}
								return <Avatar userID={taker} showName={true} badgeText={badgeText}></Avatar>;
							})}
					</div>
				</div>
				<div class="extra-properties">
					{item.price !== undefined && (
						<div className="price">
							<EditableInput
								type="currency"
								value={item.price?.toString() || ""}
								onChange={updatePrice}
								editAllowed={actuallyEditAllowed}
							/>
						</div>
					)}
					{currentUserBought() && (
						<div class="bought">
							<div class="bought-text">Bought</div>
							<RemoveProperty removeFunction={() => onBoughtRemove()} />
						</div>
					)}
					{item.priority && (
						<div class="priority">
							<div class="priority-text">Priority!</div>
							{actuallyEditAllowed && (
								<RemoveProperty removeFunction={() => removeProperty("priority")} />
							)}
						</div>
					)}
					{item.allowMultiple && (
						<div className="allow-multiple">
							<p class="multiple-text">Multiple</p>
							{actuallyEditAllowed && (
								<RemoveProperty removeFunction={() => removeProperty("allowMultiple")} />
							)}
						</div>
					)}
					{item.allowMultipleWithLimit && (
						<div className="allow-multiple-with-limit">
							<p class="multiple-text">
								Multiple {!actuallyEditAllowed && <>- {item.allowMultipleWithLimit}</>}
							</p>
							{actuallyEditAllowed && (
								<>
									<div class="stepper">
										<button
											class="button minus-button"
											onClick={onMinusClick}
											disabled={item.allowMultipleWithLimit === 2}>
											<RemoveIcon></RemoveIcon>
										</button>
										<p class="limit">{item.allowMultipleWithLimit}</p>
										<button class="button add-button" onClick={onAddClick}>
											<AddIcon></AddIcon>
										</button>
									</div>
									<RemoveProperty removeFunction={() => removeProperty("allowMultipleWithLimit")} />
								</>
							)}
						</div>
					)}
					{item.links && <LinkOutput links={item.links} itemId={id} editAllowed={actuallyEditAllowed} />}
					{item.description && (
						<div className="description">
							<EditableInput
								type="textarea"
								value={item.description || ""}
								onChange={updateDescription}
								editAllowed={actuallyEditAllowed}
							/>
						</div>
					)}
				</div>
			</div>
			{canTake && (
				<div class="take-and-menu">
					<button className="button take" onClick={onTakeClick} aria-pressed={takenByCurrentUser}>
						<Avatar userID={currentUserID}></Avatar>
					</button>
					{takenByCurrentUser && (
						<div class="take-menu button">
							<SimpleMenuIcon></SimpleMenuIcon>
							<select ref={takeMenuSelect} onChange={onTakeMenuChange}>
								{
									<option disabled={currentUserBought()} value="bought">
										Bought
									</option>
								}
								<option
									value="numberTaken"
									disabled={!item.allowMultiple && !item.allowMultipleWithLimit}>
									Number Taken
								</option>
							</select>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

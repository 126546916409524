import { render } from "preact";
import { App } from "./App";
import "./css/index.css";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./scroll-timeline/scroll-timeline";
import { loggedInPreviously } from "./user/user";
import { startNotifications } from "./notification/notification";
import { getDatabase, goOnline, goOffline } from "firebase/database";
import { signal } from "@preact/signals";

declare global {
	const startLoad: number;
}

const firebaseConfig = {
	apiKey: "AIzaSyDZxC0IdFHKXQAkfQlisjNGu4F5VUS9T9g",
	authDomain: "listoapp.co.uk",
	projectId: "listo-46a0e",
	storageBucket: "listo-46a0e.appspot.com",
	messagingSenderId: "1003275259630",
	appId: "1:1003275259630:web:b8123e578d2fc82646adff",
	databaseURL: "https://listo-46a0e-default-rtdb.europe-west1.firebasedatabase.app/",
};

// const firebaseConfig = {
// 	apiKey: "AIzaSyA8kI9TSEU7FG69V9AFClIuhKFtYpE7vnc",
// 	authDomain: "listo-6121c.firebaseapp.com",
// 	databaseURL: "https://listo-6121c-default-rtdb.europe-west1.firebasedatabase.app",
// 	projectId: "listo-6121c",
// 	storageBucket: "listo-6121c.appspot.com",
// 	messagingSenderId: "942243508854",
// 	appId: "1:942243508854:web:f468d726c26ae568a1e557",
// };

initializeApp(firebaseConfig);
const auth = getAuth();

const unsubscribe = onAuthStateChanged(auth, () => {
	const endLoad = performance.now();
	const load = endLoad - startLoad;
	const minLoad = 400;
	const toLoad = Math.max(minLoad - load, 0);

	setTimeout(() => {
		document.getElementById("splash")!.remove();
		render(<App />, document.getElementById("root")!);
		unsubscribe();
	}, toLoad);
});

document.addEventListener("keydown", () => {
	document.body.classList.replace("using-mouse", "using-keyboard");
});

document.addEventListener("mousedown", () => {
	document.body.classList.replace("using-keyboard", "using-mouse");
});

export const PWA = matchMedia("(display-mode: standalone)").matches;
const isMobile = navigator.maxTouchPoints > 0;
export const android = /\b(Android)\b/.test(navigator.userAgent);
export const iOS = isMobile && !android; // User agent string on iOS PWA is misleading.

if (iOS) {
	document.documentElement.classList.add("ios");
}
if (android) {
	document.documentElement.classList.add("android");
}

export const minLoading = 350;

export const serviceWorkerRegistration = navigator.serviceWorker?.register("service-worker.js", { type: "module" });

// Initialize notifications when the user logs in.
// Stopping on logout happens in User.tsx
let firstAuthStateChange = true;
onAuthStateChanged(auth, (user) => {
	if (firstAuthStateChange) {
		firstAuthStateChange = false;
		return;
	}
	if (user && loggedInPreviously() && !!localStorage.getItem(`${user.uid}/enableNotificationsOnLogin`)) {
		startNotifications(false).catch();
	}
});

export const connected = signal(navigator.onLine);
addEventListener("online", () => {
	connected.value = true;
});
addEventListener("offline", () => {
	connected.value = false;
});

// Workaround this WebKit bug => https://bugs.webkit.org/show_bug.cgi?id=247943
const db = getDatabase();
addEventListener("visibilitychange", () => {
	if (document.visibilityState === "visible") {
		goOnline(db);
	} else {
		goOffline(db);
	}
});

import { getAuth } from "firebase/auth";
import { getDatabase, ref, remove, set } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
import { PWA, iOS, serviceWorkerRegistration } from "../index";

export async function startNotifications(alertOnDenied = true) {
	if (!("Notification" in window)) {
		if (!alertOnDenied) return Promise.reject();

		if (iOS && !PWA) {
			alert(`On iOS, notifications are only possible for web apps that have been added to the Home Screen.`);
		} else {
			alert("The permission for notifications has been denied.");
		}

		return Promise.reject();
	}

	const permission = await Notification.requestPermission();
	if (permission === "granted") {
		const messaging = getMessaging();
		getToken(messaging, {
			vapidKey: "BF_HKwNYxSz6w2AFZnGju6OwEIHtZJBiXQc83LkJDPWZcqFhp5mHjaIT_OXmlpzV-Gapj2UZACJQSR39kE8Wgq4",
			serviceWorkerRegistration: await serviceWorkerRegistration,
		}).then(async (token) => {
			if (token) {
				const database = getDatabase();
				const currentUser = getAuth().currentUser?.uid;
				const tokenRef = ref(database, `users/${currentUser}/notificationTokens/${token}`);
				await set(tokenRef, new Date().getTime());
				localStorage.setItem("notificationToken", token);
				localStorage.setItem(`${currentUser}/enableNotificationsOnLogin`, "true");
			}
		});
		return Promise.resolve();
	} else {
		if (alertOnDenied) {
			alert("The permission for notifications has been denied.");
		}

		return Promise.reject();
	}
}

export function stopNotifications(options?: { logout?: boolean }): Promise<void> {
	const currentUser = getAuth().currentUser?.uid;
	const token = localStorage.getItem("notificationToken");
	localStorage.removeItem("notificationToken");
	if (!options?.logout) {
		localStorage.removeItem(`${currentUser}/enableNotificationsOnLogin`);
	}

	const database = getDatabase();
	const tokenRef = ref(database, `users/${currentUser}/notificationTokens/${token}`);
	return remove(tokenRef);
}

export function notificationsOn(): boolean {
	return !!localStorage.getItem("notificationToken") && Notification.permission === "granted";
}

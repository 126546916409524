import { getAuth } from "firebase/auth";
import { List } from "../../pages/List/list-model";
import { FirebaseArray } from "../../util";
import { getDatabase, off, onValue, push, ref, remove, runTransaction, set } from "firebase/database";

export interface Item {
	name: string;
	fromList: string;
	description?: string;
	price?: string | number; // Number for old lists I didn't migrate
	allowMultiple?: boolean;
	allowMultipleWithLimit?: number;
	takenBy?: FirebaseArray;

	/**
	 * The user ID to number of takes.
	 * If the number of takes is one, the user ID will not appear in here.
	 */
	multipleTakes?: FirebaseArray;
	links?: {
		[key: string]: string;
	};
	order?: number;
	clientOrder?: number;
	priority?: string;
	boughtBy?: FirebaseArray;
}

export function createItem(item: Item) {
	const listID = item.fromList;
	const database = getDatabase();
	const itemID = push(ref(database, "items"), item).key || "";
	return { itemID, setItem: set(ref(database, `lists/${listID}/items/${itemID}`), true) };
}

export async function deleteItem(id: string, item?: Item) {
	const database = getDatabase();
	if (!item) {
		item = await getItem(id);
	}
	remove(ref(database, `lists/${item.fromList}/items/${id}`));
	for (const userID of Object.keys(item.takenBy || {})) {
		remove(ref(database, `users/${userID}/takenItems/${id}`));
	}
	remove(ref(database, `items/${id}`));
}

export function getItem(id: string): Promise<Item> {
	const database = getDatabase();
	const itemRef = ref(database, `items/${id}`);
	return new Promise((resolve) => {
		onValue(
			itemRef,
			(snapshot) => {
				resolve(snapshot.val());
			},
			{ onlyOnce: true }
		);
	});
}

export function itemOnValue(id: string, callback: (item: Item) => void): () => void {
	const database = getDatabase();
	const itemRef = ref(database, `items/${id}`);
	onValue(itemRef, (snapshot) => callback(snapshot.val()));
	return () => off(itemRef);
}

export function addLink(itemId: string, value: string) {
	const database = getDatabase();
	const propertyRef = ref(database, `items/${itemId}/links`);
	push(propertyRef, value);
}

export function removeLink(itemId: string, linkId: string) {
	const database = getDatabase();
	const propertyRef = ref(database, `items/${itemId}/links/${linkId}`);
	remove(propertyRef);
}

export function editProperty<T extends keyof Item>(id: string, property: T, value: NonNullable<Item[T]> | null) {
	const database = getDatabase();
	const propertyRef = ref(database, `items/${id}/${property}`);

	if (value === null) {
		remove(propertyRef);
	} else {
		set(propertyRef, value);
	}
}

export async function takeItem(itemID: string, takerID: string) {
	const database = getDatabase();
	const takenByRef = ref(database, `items/${itemID}/takenBy`);
	const usersRef = ref(database, `users/${takerID}/takenItems/${itemID}`);
	const timeStamp = new Date().getTime();

	// This state could be stale in the transaction but getting the full item in the transaction requires write access.
	const item = await getItem(itemID);

	const result = await runTransaction(takenByRef, (takenBy: FirebaseArray | null) => {
		const takesLeft = numberOfTakesLeft(takerID, { ...item, takenBy: takenBy || {} });

		if (takesLeft === 0) {
			return undefined;
		}

		return {
			...takenBy,
			[takerID]: timeStamp,
		};
	});

	if (result.committed) {
		await set(usersRef, timeStamp);
	}

	return result;
}

export async function releaseItem(itemID: string, releaserID: string) {
	const database = getDatabase();
	const itemsRef = ref(database, `items/${itemID}/takenBy/${releaserID}`);
	const usersRef = ref(database, `users/${releaserID}/takenItems/${itemID}`);
	const boughtByRef = ref(database, `items/${itemID}/boughtBy/${releaserID}`);
	const multipleTakesRef = ref(database, `items/${itemID}/multipleTakes/${releaserID}`);
	return await Promise.all([remove(itemsRef), remove(usersRef), remove(boughtByRef), remove(multipleTakesRef)]);
}

export function isHidden(list: List, item: Item, date?: "future" | "past"): boolean {
	if (date) {
		if (!list.date) {
			return date === "past";
		}
		const listDate = new Date(list.date);
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);
		const listIsInFuture = listDate.getTime() >= currentDate.getTime();
		if (listIsInFuture && date === "past") {
			return true;
		} else if (!listIsInFuture && date === "future") {
			return true;
		}
	}

	const currentUserID = getAuth().currentUser!.uid;
	const isYourList = list.owner === currentUserID;

	if (isYourList) return false;

	const currentUserTaken = !!item.takenBy?.[currentUserID];

	if (currentUserTaken) return false;

	if (list.inviteesCanSee) {
		return false;
	}

	const takesLeft = numberOfTakesLeft(currentUserID, item);
	return takesLeft === 0;
}

export function numberOfTakes(item: Item): number {
	const takenBy = Object.keys(item.takenBy || {});
	return takenBy.reduce((previousValue, currentValue) => {
		return previousValue + numberOfTakesByAUser(currentValue, item);
	}, 0);
}

export function numberOfTakesByAUser(userID: string, item: Item): number {
	const multipleTakes = item.multipleTakes?.[userID];
	if (multipleTakes) {
		return multipleTakes;
	}

	if (item.takenBy?.[userID]) {
		return 1;
	} else {
		return 0;
	}
}

export function numberOfTakesLeft(userID: string, item: Item): number {
	let takesLeft: number;
	const takenBy = Object.keys(item.takenBy || {});

	if (item.allowMultiple) {
		takesLeft = Number.MAX_SAFE_INTEGER;
	} else if (item.allowMultipleWithLimit) {
		const takes = numberOfTakes(item);
		const takesByUser = numberOfTakesByAUser(userID, item);
		takesLeft = item.allowMultipleWithLimit - takes + takesByUser;
	} else {
		if (takenBy.length && !item.takenBy?.[userID]) {
			takesLeft = 0;
		} else {
			takesLeft = 1;
		}
	}
	return takesLeft;
}

export function changeTakeCount(options: { userID: string; itemID: string; item: Item; takes: number }) {
	const { item, itemID, takes, userID } = options;

	if (!item.takenBy?.[userID]) {
		throw Error("You cannot change the take count if the item is not already taken.");
	}

	const { multipleTakes = {} } = item;

	if (takes === 1) {
		delete multipleTakes[userID];
	} else {
		multipleTakes[userID] = takes;
	}

	return editProperty(itemID, "multipleTakes", multipleTakes);
}

import { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import "./Login.css";
import { useNavigate } from "react-router";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { Link } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { connected } from "../..";

const Login: FunctionComponent = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Listo - Login";
	}, []);

	function handleInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	function handleBlur(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		event.currentTarget.setAttribute("data-touched", "true");
	}

	function handleSubmitClick(event: MouseEvent) {
		event.preventDefault();
		const auth = getAuth();
		const emailAddress = document.getElementById("emailAddress") as HTMLInputElement;
		const password = document.getElementById("password") as HTMLInputElement;

		emailAddress?.setAttribute("data-touched", "true");
		password?.setAttribute("data-touched", "true");

		if (document.querySelector("form")?.reportValidity()) {
			setLoading(true);
			signInWithEmailAndPassword(auth, emailAddress.value, password.value)
				.then(() => {
					if (auth.currentUser?.emailVerified) {
						navigate("/home");
					} else {
						navigate("/email");
					}
				})
				.catch(() => {
					alert("Incorrect email or password");
					setLoading(false);
				});
		}
	}

	function handleFocus(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	return (
		<main data-login-styles="true">
			<Link to="/login-register" className="icon-button back-button">
				<ArrowIcon></ArrowIcon>
			</Link>
			<div id="content">
				<div class="headings">
					<h1>
						<ListoIcon></ListoIcon>
						<div>Listo</div>
					</h1>
					<h2>Login</h2>
				</div>
				<form>
					<div>
						<label htmlFor="emailAddress">Email</label>
						<input
							id="emailAddress"
							type="email"
							enterkeyhint="go"
							required
							autoComplete="email"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleInput}
						/>
					</div>
					<div>
						<label htmlFor="password">Password</label>
						<input
							id="password"
							type="password"
							enterkeyhint="go"
							required
							autoComplete="current-password"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleInput}
						/>
					</div>
					<Link className="link" to="/forgotten-password">
						Forgotten your password?
					</Link>
					<button
						className="button"
						data-primary="true"
						onClick={handleSubmitClick}
						disabled={loading || !connected.value}>
						Login
					</button>
				</form>
			</div>
		</main>
	);
};

export default Login;

import { useState } from "preact/hooks";
import "./PasswordReset.css";
import { useNavigate } from "react-router";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { Link } from "react-router-dom";
import { confirmPasswordReset, getAuth, verifyPasswordResetCode } from "firebase/auth";
import { useEffect } from "react";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { connected } from "../..";

const PasswordReset = ({ code }: { code: string }) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [canReset, setCanReset] = useState(false);

	useEffect(() => {
		document.title = "Listo - Password Reset";
	}, []);

	useEffect(() => {
		verifyPasswordResetCode(getAuth(), code)
			.then(() => {
				setCanReset(true);
			})
			.catch(() => {
				alert("Your password cannot be reset. Please try again.");
				navigate("/forgotten-password");
			});
	});

	function handleBlur(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		event.currentTarget.setAttribute("data-touched", "true");
	}

	function handleFocus(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	function handleInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	function handleNewPasswordInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		const passwordConfirmation = document.getElementById("passwordConfirmation") as HTMLInputElement;

		if (event.currentTarget.value.length >= 6) {
			event.currentTarget.setCustomValidity("");
		} else {
			event.currentTarget.setCustomValidity("Passwords must be at least 6 characters long");
		}

		if (event.currentTarget.value === passwordConfirmation.value) {
			passwordConfirmation.setCustomValidity("");
		} else {
			passwordConfirmation.setCustomValidity("Passwords do not match");
		}

		handleInput(event);
	}

	function handlePasswordConfirmationInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.value === (document.getElementById("newPassword") as HTMLInputElement).value) {
			event.currentTarget.setCustomValidity("");
		} else {
			event.currentTarget.setCustomValidity("Passwords do not match");
		}

		handleInput(event);
	}

	function handleResetPasswordClick(event: MouseEvent) {
		event.preventDefault();
		const auth = getAuth();
		const newPassword = document.getElementById("newPassword") as HTMLInputElement;

		if (document.querySelector("form")?.reportValidity()) {
			setLoading(true);
			confirmPasswordReset(auth, code, newPassword.value)
				.then(() => {
					alert("Your password has been successfully changed");
					navigate("/login");
				})
				.catch(() => {
					alert("Your password was unable to be reset. Please try again.");
					setLoading(false);
				});
		}
	}

	if (!canReset) return <></>;

	return (
		<main data-password-reset-styles="true">
			<Link to="/login-register" className="icon-button back-button">
				<ArrowIcon></ArrowIcon>
			</Link>
			<div id="content">
				<div class="headings">
					<h1>
						<ListoIcon></ListoIcon>
						<div>Listo</div>
					</h1>
					<h2>Password Reset</h2>
				</div>
				<form>
					<div class="input-container">
						<label htmlFor="newPassword">Password</label>
						<input
							id="newPassword"
							type="password"
							required
							autoComplete="new-password"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleNewPasswordInput}
							enterkeyhint="go"
						/>
					</div>
					<div class="input-container">
						<label htmlFor="passwordConfirmation">Re-enter your password</label>
						<input
							id="passwordConfirmation"
							type="password"
							required
							autoComplete="new-password"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handlePasswordConfirmationInput}
							enterkeyhint="go"
						/>
					</div>
					<button
						className="button"
						data-primary="true"
						onClick={handleResetPasswordClick}
						disabled={loading || !connected.value}>
						Reset Password
					</button>
				</form>
			</div>
		</main>
	);
};

export default PasswordReset;

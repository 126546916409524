import { useState } from "preact/hooks";

export const ListoIcon = ({ states = false }: { states?: boolean }) => {
	const [neat, setNeat] = useState(false);

	const writingOneD =
		"M195.455 151.507C198.332 148.308 207.837 144.018 217.097 146.947C226.357 149.876 230.143 155.448 240.866 154.616C251.59 153.783 250.24 133.643 269.999 146.514C289.758 159.385 290.779 176.575 304.883 157.001C318.986 137.427 322.297 152.359 322.297 152.359C322.297 152.359 335.52 189.145 346.238 174.275C361.24 153.461 349.861 140.971 368.899 160.732C387.938 180.494 393.631 167.17 406.35 158.966";
	const writingTwoD =
		"M194.792 235.224C198.687 228.079 200.786 224.893 203.656 227.697C211.008 242.692 209.041 245.064 214.636 244.128C220.23 243.191 226.225 225.204 237.057 225.089C251.248 224.939 244.286 256.911 258.466 257.129C269.822 257.303 270.626 237.185 281.989 237.156C296.653 237.119 287.175 245.248 301.548 248.025C317.319 251.073 322.437 235.965 337.429 241.983C346.998 245.824 343.061 262.319 352.927 265.175C365.696 268.872 368.078 236.791 380.447 241.808C388.285 244.987 393.157 260.829 393.157 260.829";
	const writingThreeD =
		"M188.261 332.069C195.336 344.907 216.275 357.996 219.816 348.064C223.356 338.133 226.779 326.345 238.271 327.399C249.762 328.454 248.273 345.407 260.232 344.291C272.19 343.174 280.625 309.827 293.039 308.668C305.453 307.508 292.196 342.707 305.187 340.093C318.179 337.479 314.571 326.347 323.642 319.428C332.713 312.509 345.046 339.33 356.734 344.881C368.422 350.432 364.053 322.972 375.037 324.386C381.743 325.249 377.946 332.21 388.334 334.613C398.723 337.016 418.367 300.983 418.367 300.983";

	const writingOneNeatD = "M195.455 151.507L406.35 158.966";
	const writingTwoNeatD = "M194.792 235.224L393.157 260.829";
	const writingThreeNeatD = "M188.26 332.069L394.074 303.251";

	let writingD;
	if (neat) {
		writingD = { one: writingOneNeatD, two: writingTwoNeatD, three: writingThreeNeatD };
	} else {
		writingD = { one: writingOneD, two: writingTwoD, three: writingThreeD };
	}

	return (
		<svg
			width="60"
			height="60"
			viewBox="0 0 500 500"
			fill="none"
			class="listo-icon"
			onPointerOver={() => states && setNeat(true)}
			onPointerOut={() => states && setNeat(false)}
			onPointerDown={() => states && setNeat(true)}
			onPointerUp={() => states && setNeat(false)}
			onPointerCancel={() => states && setNeat(false)}>
			<rect width="500" height="500" rx="120" fill="none" />
			<g filter="url(#filter0_d_1218_471)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M68.0636 51.3959C78.8928 49.2197 89.4357 56.2343 91.6119 67.0635C96.3918 90.8497 98.5278 122.806 98.5154 176.48C98.5029 230.36 96.3054 307.341 91.9897 421.758C91.5733 432.796 82.2879 441.406 71.25 440.99C60.2122 440.573 51.6017 431.288 52.0181 420.25C56.3348 305.807 58.5031 229.513 58.5154 176.471C58.5277 123.222 56.3474 94.6081 52.3959 74.9442C50.2197 64.115 57.2344 53.572 68.0636 51.3959Z"
					fill="var(--l-fill)"
				/>
				<path
					d="M91.6119 67.0635L92.1021 66.965L92.1021 66.965L91.6119 67.0635ZM98.5154 176.48L98.0154 176.48L98.0154 176.48L98.5154 176.48ZM91.9897 421.758L92.4893 421.777L92.4893 421.777L91.9897 421.758ZM71.25 440.99L71.2312 441.489L71.2312 441.489L71.25 440.99ZM52.0181 420.25L51.5184 420.231L51.5184 420.231L52.0181 420.25ZM58.5154 176.471L59.0154 176.471L58.5154 176.471ZM52.3959 74.9442L51.9057 75.0427L51.9057 75.0427L52.3959 74.9442ZM92.1021 66.965C89.8715 55.8651 79.065 48.6751 67.965 50.9057L68.1621 51.8861C78.7205 49.7643 88.9999 56.6036 91.1217 67.162L92.1021 66.965ZM99.0154 176.48C99.0278 122.801 96.8924 90.8027 92.1021 66.965L91.1217 67.162C95.8913 90.8967 98.0278 122.811 98.0154 176.48L99.0154 176.48ZM92.4893 421.777C96.8051 307.36 99.0029 230.371 99.0154 176.48L98.0154 176.48C98.0029 230.35 95.8058 307.322 91.49 421.739L92.4893 421.777ZM71.2312 441.489C82.545 441.916 92.0626 433.09 92.4893 421.777L91.49 421.739C91.0841 432.501 82.0308 440.896 71.2689 440.49L71.2312 441.489ZM51.5184 420.231C51.0917 431.545 59.9174 441.063 71.2312 441.489L71.2689 440.49C60.507 440.084 52.1118 431.031 52.5177 420.269L51.5184 420.231ZM58.0154 176.47C58.0031 229.502 55.8352 305.788 51.5184 420.231L52.5177 420.269C56.8345 305.826 59.0031 229.523 59.0154 176.471L58.0154 176.47ZM51.9057 75.0427C55.8469 94.6551 58.0277 123.227 58.0154 176.47L59.0154 176.471C59.0277 123.217 56.848 94.5612 52.8861 74.8457L51.9057 75.0427ZM67.965 50.9057C56.8651 53.1363 49.6751 63.9428 51.9057 75.0427L52.8861 74.8457C50.7643 64.2872 57.6036 54.0078 68.1621 51.8861L67.965 50.9057Z"
					fill="var(--l-fill)"
				/>
			</g>
			<g class="writing-one" filter="url(#filter1_d_1218_471)">
				<path d={writingD.one} stroke="var(--writing-fill)" stroke-width="12" stroke-linecap="round" />
			</g>
			<g class="writing-three" filter="url(#filter2_d_1218_471)">
				<path d={writingD.three} stroke="var(--writing-fill)" stroke-width="12" />
			</g>
			<g class="writing-two" filter="url(#filter3_d_1218_471)">
				<path d={writingD.two} stroke="var(--writing-fill)" stroke-width="12" />
			</g>
			<g filter="url(#filter4_d_1218_471)">
				<path
					d="M127.17 145.409C128.465 140.933 129.113 138.695 130.355 137.141C132.011 135.07 134.422 133.741 137.058 133.447C139.035 133.227 141.272 133.874 145.748 135.17L155.354 137.949C159.83 139.245 162.068 139.892 163.621 141.134C165.693 142.79 167.022 145.201 167.316 147.837C167.536 149.814 166.888 152.052 165.593 156.528V156.528C164.298 161.004 163.65 163.242 162.408 164.795C160.752 166.867 158.341 168.196 155.705 168.489C153.728 168.71 151.491 168.062 147.015 166.767L137.409 163.987C132.933 162.692 130.695 162.044 129.142 160.802C127.07 159.146 125.741 156.735 125.447 154.099C125.227 152.122 125.875 149.885 127.17 145.409V145.409Z"
					fill="var(--dot-fill)"
				/>
				<path
					d="M145.609 135.65L155.215 138.43C157.46 139.079 159.119 139.56 160.412 140.031C161.701 140.502 162.592 140.952 163.309 141.525C165.277 143.098 166.54 145.389 166.819 147.893C166.92 148.805 166.825 149.798 166.534 151.139C166.242 152.485 165.762 154.144 165.113 156.389C164.463 158.633 163.983 160.293 163.511 161.586C163.041 162.875 162.591 163.766 162.018 164.483C160.445 166.451 158.154 167.713 155.65 167.993C154.738 168.094 153.744 167.999 152.403 167.708C151.058 167.416 149.398 166.936 147.154 166.287L137.548 163.507C135.303 162.857 133.644 162.377 132.351 161.905C131.062 161.435 130.171 160.985 129.454 160.412C127.486 158.839 126.223 156.548 125.944 154.044C125.843 153.132 125.938 152.138 126.229 150.797C126.521 149.452 127.001 147.792 127.65 145.548C128.3 143.303 128.78 141.644 129.252 140.351C129.722 139.062 130.172 138.17 130.745 137.454C132.318 135.486 134.609 134.223 137.113 133.944C138.025 133.842 139.019 133.938 140.36 134.229C141.705 134.521 143.365 135 145.609 135.65Z"
					stroke="var(--dot-fill)"
				/>
			</g>
			<g filter="url(#filter5_d_1218_471)">
				<path
					d="M129.652 239.314C129.139 234.683 128.882 232.367 129.436 230.457C130.175 227.91 131.896 225.761 134.219 224.482C135.962 223.523 138.278 223.266 142.909 222.753L152.848 221.652C157.479 221.139 159.795 220.882 161.705 221.436C164.252 222.175 166.401 223.896 167.68 226.219C168.639 227.962 168.896 230.278 169.409 234.909V234.909C169.922 239.54 170.178 241.855 169.624 243.766C168.885 246.313 167.165 248.462 164.841 249.741C163.099 250.7 160.783 250.956 156.152 251.469L146.213 252.571C141.582 253.084 139.266 253.34 137.356 252.786C134.809 252.047 132.659 250.327 131.381 248.003C130.422 246.261 130.165 243.945 129.652 239.314V239.314Z"
					fill="var(--dot-fill)"
				/>
				<path
					d="M142.964 223.25L152.903 222.149C155.225 221.892 156.942 221.702 158.318 221.643C159.689 221.585 160.684 221.661 161.566 221.917C163.985 222.618 166.027 224.253 167.242 226.46C167.684 227.264 167.976 228.219 168.219 229.57C168.464 230.924 168.654 232.641 168.912 234.964C169.169 237.286 169.359 239.003 169.417 240.378C169.475 241.75 169.4 242.745 169.144 243.626C168.442 246.046 166.808 248.088 164.6 249.303C163.796 249.745 162.842 250.037 161.491 250.28C160.136 250.525 158.419 250.715 156.097 250.972L146.158 252.074C143.835 252.331 142.119 252.521 140.743 252.579C139.372 252.637 138.376 252.562 137.495 252.306C135.075 251.604 133.034 249.97 131.819 247.762C131.376 246.958 131.085 246.004 130.841 244.653C130.597 243.298 130.406 241.581 130.149 239.259C129.892 236.937 129.702 235.22 129.644 233.844C129.586 232.473 129.661 231.478 129.917 230.596C130.619 228.177 132.253 226.135 134.461 224.92C135.264 224.478 136.219 224.186 137.57 223.942C138.925 223.698 140.641 223.508 142.964 223.25Z"
					stroke="var(--dot-fill)"
				/>
			</g>
			<g filter="url(#filter6_d_1218_471)">
				<path
					d="M128.492 346.605C126.786 342.269 125.933 340.101 125.969 338.112C126.016 335.46 127.115 332.936 129.023 331.094C130.455 329.713 132.622 328.86 136.958 327.154L146.264 323.492C150.6 321.786 152.767 320.933 154.756 320.969C157.408 321.016 159.932 322.115 161.774 324.023C163.155 325.454 164.008 327.622 165.714 331.958V331.958C167.421 336.294 168.274 338.462 168.238 340.451C168.191 343.102 167.092 345.627 165.184 347.468C163.752 348.85 161.585 349.703 157.249 351.409L147.943 355.071C143.607 356.777 141.439 357.63 139.451 357.594C136.799 357.547 134.275 356.448 132.433 354.54C131.052 353.108 130.199 350.941 128.492 346.605V346.605Z"
					fill="var(--dot-fill)"
				/>
				<path
					d="M137.141 327.619L146.447 323.958C148.621 323.102 150.229 322.47 151.541 322.054C152.849 321.64 153.83 321.452 154.747 321.469C157.267 321.513 159.665 322.557 161.414 324.37C162.051 325.031 162.582 325.876 163.171 327.116C163.761 328.36 164.394 329.967 165.249 332.141C166.105 334.316 166.737 335.923 167.153 337.236C167.567 338.544 167.755 339.524 167.738 340.442C167.693 342.961 166.65 345.359 164.837 347.109C164.176 347.746 163.331 348.277 162.091 348.865C160.847 349.455 159.24 350.088 157.066 350.944L147.76 354.605C145.586 355.461 143.978 356.093 142.666 356.509C141.358 356.923 140.377 357.111 139.46 357.094C136.94 357.049 134.542 356.006 132.793 354.193C132.155 353.532 131.624 352.687 131.036 351.447C130.446 350.203 129.813 348.596 128.958 346.422C128.102 344.247 127.47 342.64 127.054 341.327C126.64 340.019 126.452 339.039 126.469 338.121C126.514 335.602 127.557 333.204 129.37 331.454C130.031 330.817 130.876 330.286 132.116 329.698C133.36 329.108 134.967 328.475 137.141 327.619Z"
					stroke="var(--dot-fill)"
				/>
			</g>
			<g filter="url(#filter7_d_1218_471)">
				<path
					d="M74 422.999C215.5 458.501 294 422.999 419.5 413"
					stroke="#7B61FF"
					stroke-width="45"
					stroke-linecap="round"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1218_471"
					x="51.5"
					y="50.5"
					width="55.5156"
					height="399.004"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="4" dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1218_471" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1218_471" result="shape" />
				</filter>
				<filter
					id="filter1_d_1218_471"
					x="189.455"
					y="136.228"
					width="230.896"
					height="55.5889"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="4" dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1218_471" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1218_471" result="shape" />
				</filter>
				<filter
					id="filter2_d_1218_471"
					x="183.006"
					y="298.11"
					width="248.628"
					height="67.4502"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="4" dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1218_471" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1218_471" result="shape" />
				</filter>
				<filter
					id="filter3_d_1218_471"
					x="189.524"
					y="219.089"
					width="217.368"
					height="60.3838"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="4" dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1218_471" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1218_471" result="shape" />
				</filter>
				<filter
					id="filter4_d_1218_471"
					x="125.405"
					y="133.404"
					width="49.9531"
					height="43.1279"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="4" dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1218_471" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1218_471" result="shape" />
				</filter>
				<filter
					id="filter5_d_1218_471"
					x="129.125"
					y="221.125"
					width="48.8105"
					height="39.9727"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="4" dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1218_471" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1218_471" result="shape" />
				</filter>
				<filter
					id="filter6_d_1218_471"
					x="125.968"
					y="320.968"
					width="50.2715"
					height="44.6279"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="4" dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1218_471" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1218_471" result="shape" />
				</filter>
				<filter
					id="filter7_d_1218_471"
					x="51.4946"
					y="390.499"
					width="398.506"
					height="78.4316"
					filterUnits="userSpaceOnUse"
					color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dx="4" dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1218_471" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1218_471" result="shape" />
				</filter>
			</defs>
		</svg>
	);
};

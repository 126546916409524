import { FunctionComponent } from "preact";
import { useState, useEffect, useContext, useRef, useLayoutEffect } from "preact/hooks";
import "./User.css";
import { setTheme } from "../../theme/theme";
import { Link, useNavigate } from "react-router-dom";
import { UserDetailsContext } from "../LoggedIn/LoggedIn";
import { EditableInput } from "../../components/Editable-Input/EditableInput";
import { getAuth, signOut } from "firebase/auth";
import { deleteAccount, setAvatar, setName } from "../../user/user";
import { Avatar } from "../../components/Avatar/Avatar";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { notificationsOn, startNotifications, stopNotifications } from "../../notification/notification";
import { connected } from "../..";

export const User: FunctionComponent = () => {
	const userDetails = useContext(UserDetailsContext)!;
	const [selectedTheme, setSelectedTheme] = useState(getTheme());
	const auth = getAuth();
	const navigate = useNavigate();
	const notificationToggle = useRef<HTMLInputElement>(null);

	useEffect(() => {
		document.title = `Listo - ${userDetails.name || ""}`;
	}, [userDetails]);

	useEffect(() => {
		setTheme(selectedTheme);
	}, [selectedTheme]);

	useLayoutEffect(() => {
		notificationToggle.current!.checked = notificationsOn();
	}, []);

	function clickFileInput() {
		document.getElementById("fileInput")?.click();
	}

	function changeName(value: string) {
		setName(value, userDetails!);
	}

	function onNotificationInput(event: Event) {
		if (!(event.target instanceof HTMLInputElement)) return;

		if (event.target.checked) {
			event.target.checked = false; // Wait to see if permission is rejected first.
			startNotifications().then(() => {
				(event.target as HTMLInputElement).checked = true;
			});
		} else {
			stopNotifications();
		}
	}

	async function handleSignOut() {
		await stopNotifications({ logout: true });
		const uid = getAuth().currentUser!.uid;
		localStorage.removeItem(`${uid}/name`);
		localStorage.removeItem(`${uid}/photoURL`);
		caches.delete("currentUser");
		signOut(auth).finally(() => navigate("/login-register"));
	}

	function onInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}
		const file = event.currentTarget.files?.[0];
		if (!file) return;
		setAvatar(file, userDetails!);
	}

	function accountBye() {
		deleteAccount(navigate);
	}

	return (
		<div data-user-styles="true">
			<header>
				<Link to={"/home"} className="link listo-icon">
					<ListoIcon states={true}></ListoIcon>
				</Link>
				<h1>{userDetails.name}</h1>
			</header>
			<div id="content">
				<input hidden id="fileInput" type="file" onInput={onInput} accept="image/*" />
				<div class="avatar-and-name">
					<div class="avatar-container">
						<button
							class={!userDetails.photoURL ? "icon-button avatar-button" : "avatar-button"}
							onClick={clickFileInput}
							disabled={!connected.value}>
							<Avatar userID={auth.currentUser?.uid || ""}></Avatar>
						</button>
					</div>
					<div id="displayNameContainer">
						<EditableInput
							type="text"
							value={userDetails.name || ""}
							onChange={changeName}
							editAllowed={true}></EditableInput>
					</div>
				</div>
				<div class="old-stuff">
					<Link to="/old-lists" className="button">
						Old Lists
					</Link>
					<Link to="/old-items" className="button">
						Old Items
					</Link>
				</div>
				<div className="blocks">
					<div class="appearance">
						<fieldset>
							<legend>Appearance</legend>
							<div>
								<div className="radio">
									<input
										name="theme"
										type="radio"
										id="osDefault"
										onChange={() => setSelectedTheme("osDefault")}
										checked={selectedTheme === "osDefault"}
									/>
									<label htmlFor="osDefault">Automatic</label>
								</div>
								<div className="radio">
									<input
										name="theme"
										type="radio"
										id="light"
										onChange={() => setSelectedTheme("light")}
										checked={selectedTheme === "light"}
									/>
									<label htmlFor="light">Light</label>
								</div>
								<div className="radio">
									<input
										name="theme"
										type="radio"
										id="dark"
										onChange={() => setSelectedTheme("dark")}
										checked={selectedTheme === "dark"}
									/>
									<label htmlFor="dark">Dark</label>
								</div>
							</div>
						</fieldset>
					</div>
					<div class="buttons">
						<button className="button" onClick={handleSignOut} disabled={!connected.value}>
							Logout
						</button>
						{auth.currentUser?.providerData.some((data) => data?.providerId === "password") && (
							<Link className="button" to="/change-password">
								Change Password
							</Link>
						)}
						<button className="button" onClick={accountBye} disabled={!connected.value}>
							Delete Account
						</button>
					</div>
				</div>
				<div class="notifications">
					<h2>Notifications</h2>
					<label>
						Receive a notification on this device when someone invites you to a list
						<input
							type="checkbox"
							role="switch"
							ref={notificationToggle}
							disabled={!connected.value}
							onInput={onNotificationInput}></input>
					</label>
				</div>
				<div class="links">
					<Link to="/about" className="link">
						About Listo
					</Link>
				</div>
			</div>
		</div>
	);
};

import { useSignal } from "@preact/signals";
import "./Friends.css";
import { Friends as FriendsModel, friendsOnValue, removeFriend } from "./friends-model";
import { useEffect } from "react";
import { User, getUser } from "../../user/user";
import { UserRemoveIcon } from "../../components/icons/UserRemoveIcon";
import { Avatar } from "../../components/Avatar/Avatar";
import { Link, useNavigate } from "react-router-dom";
import { UserAddIcon } from "../../components/icons/UserAddIcon";
import { setInPageNavigation } from "./AddFriends/AddFriends";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { minLoading } from "../../index";

export function Friends() {
	const friendIDs = useSignal<FriendsModel>({});
	const friends = useSignal<{ [userID: string]: User }>({});
	const loading = useSignal(true);

	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Listo - Friends";
	}, []);

	useEffect(() => {
		return friendsOnValue((value) => {
			const newFriends: { [userID: string]: User } = {};
			const userCalls: Promise<[string, User]>[] = [];
			for (const id in value) {
				if (id in friends) {
					newFriends[id] = friends.value[id];
				} else {
					userCalls.push(
						getUser(id).then((user) => {
							return [id, user];
						})
					);
				}
			}
			Promise.all(userCalls).then((result) => {
				for (const [id, user] of result) {
					newFriends[id] = user;
				}
				friendIDs.value = value || {};
				friends.value = newFriends;
				setTimeout(() => {
					loading.value = false;
				}, minLoading);
			});
		});
	}, [friendIDs, friends, loading]);

	function navigateToAddFriends() {
		setInPageNavigation(true);
		navigate("/add-friends");
	}

	return (
		<div id="friends">
			<header>
				<Link to={"/home"} className="link listo-icon">
					<ListoIcon states={true}></ListoIcon>
				</Link>
				<h1>Friends</h1>
				<button className="icon-button add-friends-button" onClick={navigateToAddFriends}>
					<UserAddIcon />
				</button>
			</header>
			{loading.value && (
				<div class="message">
					<p>Loading...</p>
				</div>
			)}
			{!loading.value && !Object.keys(friends.value).length && (
				<div class="message">
					<p>You have no friends yet!</p>
				</div>
			)}
			{!loading.value && !!Object.keys(friends.value).length && (
				<div class="results">
					{Object.entries(friends.value)
						.sort(([userIdA], [userIdB]) => {
							return friendIDs.value[userIdA] - friendIDs.value[userIdB];
						})
						.map(([userID, user]) => {
							return (
								<div class="result" key={userID}>
									<div class="avatar-and-name">
										<div class="avatar-container">
											<Avatar userID={userID} user={user}></Avatar>
										</div>
										<h2>{user.name}</h2>
									</div>
									{userID in (friends.value || {}) && (
										<button
											class="icon-button remove-friend-button"
											onClick={() => removeFriend(userID)}>
											<UserRemoveIcon></UserRemoveIcon>
										</button>
									)}
								</div>
							);
						})}
				</div>
			)}
		</div>
	);
}

import { FunctionComponent } from "preact";

export const SearchIcon: FunctionComponent = () => {
	return (
		<svg viewBox="0 0 512 512">
			<path
				d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
				fill="none"
				stroke="currentColor"
				stroke-miterlimit="10"
				stroke-width="32"
			/>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-miterlimit="10"
				stroke-width="32"
				d="M338.29 338.29L448 448"
			/>
		</svg>
	);
};

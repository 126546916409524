import GoogleLoginButton from "../../components/GoogleLoginButton/GoogleLoginButton";
import "./LoginRegister.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { UserCredential } from "firebase/auth";
import { createUser, getUser } from "../../user/user";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { useEffect } from "preact/hooks";

const LoginRegister = () => {
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Listo";
	}, []);

	function handleGoogleLogin(popUpPromise: Promise<UserCredential>) {
		popUpPromise
			.then(async (userCredential) => {
				const user = await getUser(userCredential.user.uid);
				if (!user) {
					await createUser(
						userCredential.user.uid,
						userCredential.user.displayName!,
						userCredential.user.photoURL || ""
					);
				}
				navigate("/home");
			})
			.catch((error) => {
				if (error.code === "auth/popup-closed-by-user") return;
				alert(error.message);
			});
	}

	return (
		<main data-login-register-styles="true">
			<div id="content">
				<div class="headings">
					<h1>
						<ListoIcon></ListoIcon> <div>Listo</div>
					</h1>
					<h2>Create and share lists!</h2>
				</div>
				<div class="buttons">
					<Link data-primary="true" className="button" to="/login">
						Continue with Email
					</Link>
					<Link data-primary="true" className="button" to="/register">
						Register
					</Link>
					<GoogleLoginButton logInCallback={handleGoogleLogin}></GoogleLoginButton>
				</div>
			</div>
			<div class="links">
				<Link to="/about" className="link">
					About Listo
				</Link>
			</div>
		</main>
	);
};

export default LoginRegister;

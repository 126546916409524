import { FunctionComponent } from "preact";

export const AddIcon: FunctionComponent = () => {
	return (
		<svg viewBox="0 0 512 512">
			<path
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="52"
				d="M256 56 v400 M456 256 H56"
			/>
		</svg>
	);
};

import { FunctionComponent } from "preact";
import { useState, useEffect, useRef } from "preact/hooks";
import "./List.css";
import { TabOptions, Tabs } from "../../components/Tabs/Tabs";
import { useNavigate, useParams } from "react-router-dom";
import { ItemsPanel, ItemsPanelProps } from "../../components/ItemsPanel/ItemsPanel";
import { InviteesPanel } from "../../components/InviteesPanel/InviteesPanel";
import { createItem, Item } from "../../components/Item/item-model";
import { AddIcon } from "../../components/icons/AddIcon";
import {
	List as ListModel,
	createNewListWithNonTakenItems,
	editListProperty,
	freezeList,
	isFrozen,
	listOnValue,
} from "./list-model";
import { FirebaseArray } from "../../util";
import { EditableInput } from "../../components/Editable-Input/EditableInput";
import { isAuthedsList } from "../../user/user";
import { UserAddIcon } from "../../components/icons/UserAddIcon";
import { ListSettings } from "../../components/ListSettings/ListSettings";
import { Avatar } from "../../components/Avatar/Avatar";
import { MenuIcon } from "../../components/icons/MenuIcon";
import { FrozenIcon } from "../../components/icons/FrozenIcon";
import { SimpleMenuIcon } from "../../components/icons/SimpleMenuIcon";

type RouteParams = {
	listId: string;
};

export const List: FunctionComponent = () => {
	const { listId = "" } = useParams<RouteParams>();
	const [list, setList] = useState<ListModel>();
	const [name, setName] = useState("");
	const [date, setDate] = useState("");
	const [invitees, setInvitees] = useState<FirebaseArray>();
	const [isCurrentUsersList, setIsCurrentUsersList] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [tabs, setTabs] = useState<TabOptions[]>();
	const [selectedTab, setSelectedTab] = useState("items");
	const navigate = useNavigate();
	const itemsTabSelectEl = useRef<HTMLSelectElement>(null);
	const settingsMenuSelectEl = useRef<HTMLSelectElement>(null);
	const [itemsSortOrder, setItemsSortOrder] = useState<ItemsPanelProps["sortOrder"]>("default");

	useEffect(() => {
		document.title = `Listo - ${list?.name || ""}`;
	}, [list]);

	useEffect(() => {
		return listOnValue(listId, (list) => {
			if (!list) {
				navigate("/home");
				return;
			}
			setList(list);
			setName(list.name);
			setDate(list.date);
			setInvitees(list.invitees || {});
			setIsCurrentUsersList(isAuthedsList(list.owner));
			setInitialLoading(false);
		});
	}, [listId, navigate]);

	// Only render the tabs once we have fetched the data.
	useEffect(() => {
		if (initialLoading) return;

		function onItemsTabMenuChange(event: Event) {
			if (!(event.target instanceof HTMLSelectElement)) {
				return;
			}

			setItemsSortOrder(event.target.value as ItemsPanelProps["sortOrder"]);

			if (isCurrentUsersList) {
				event.target.value = "";
				setTimeout(() => {
					setItemsSortOrder("default");
				});
			}
		}

		setTabs([
			{
				label: <>Items</>,
				afterButton: (
					<div class="icon-button tab-menu">
						<SimpleMenuIcon></SimpleMenuIcon>
						<select ref={itemsTabSelectEl} onChange={onItemsTabMenuChange}>
							<optgroup label="Sort">
								{!isCurrentUsersList && <option value="default">Default</option>}
								<option value="priority">Priority</option>
								<option value="priceLowToHigh">Price - Low to High</option>
								<option value="priceHighToLow">Price - High to Low</option>
							</optgroup>
						</select>
					</div>
				),
				panel: <ItemsPanel list={list!} key={listId} sortOrder={itemsSortOrder} />,
				fragmentURL: "items",
			},
			{
				label: <>Invited</>,
				panel: (
					<InviteesPanel
						listID={listId}
						isCurrentUsersList={isCurrentUsersList}
						invitees={invitees!}
						key={listId}
					/>
				),
				fragmentURL: "invited",
			},
			{
				label: (
					<div class="settings-label">
						{isFrozen(list!) && (
							<div class="frozen">
								<FrozenIcon></FrozenIcon>
							</div>
						)}{" "}
						Settings
					</div>
				),
				panel: <ListSettings list={list!} listID={listId} key={listId}></ListSettings>,
				fragmentURL: "settings",
			},
		]);
	}, [isCurrentUsersList, invitees, initialLoading, listId, list, itemsSortOrder]);

	useEffect(() => {
		if (!tabs || !isCurrentUsersList) return;

		if (itemsTabSelectEl.current) {
			itemsTabSelectEl.current.value = "";
		}
	}, [tabs, isCurrentUsersList]);

	async function addItem() {
		const item: Item = {
			fromList: listId,
			name: "New Item",
			allowMultiple: false,
			order: Object.keys(list?.items || {}).length,
		};
		const { setItem } = createItem(item);
		await setItem;
		setTimeout(() => {
			const itemsElement = document.getElementById("items");
			if (itemsElement) {
				itemsElement.scroll({ top: itemsElement.scrollHeight, behavior: "smooth" });
			}
		}, 50);
	}

	function navigateToAddUsers() {
		navigate(`/list/${listId}/add-invitee`);
	}

	function updateListName(value: string) {
		if (value === "") {
			const prevName = name;
			setName("");
			setTimeout(() => setName(prevName));
		} else {
			editListProperty(listId, "name", value);
		}
	}

	function updateListDate(value: string) {
		editListProperty(listId, "date", value);
	}

	function updateIconButton(fragmentURL: string) {
		setSelectedTab(fragmentURL);
		setTimeout(() => {
			if (settingsMenuSelectEl.current) {
				settingsMenuSelectEl.current.value = "";
			}
		});
	}

	async function onSettingsMenuChange(event: Event) {
		if (!(event.target instanceof HTMLSelectElement)) {
			return;
		}

		const selected = event.target.value;

		switch (selected) {
			case "newList": {
				const newListID = await createNewListWithNonTakenItems(list!);
				setInitialLoading(true);
				navigate(`/list/${newListID}`);
				document.getElementById("itemsTab")?.click();
				break;
			}
			case "freezeList": {
				freezeList(listId, list!.date);
				break;
			}
			default:
				break;
		}

		event.target.value = "";
	}

	return (
		<div data-list-styles="true">
			{tabs && (
				<div id="content">
					<header>
						<div class="date-and-add">
							{(isCurrentUsersList || date) && (
								<div className="date">
									{isFrozen(list!) && (
										<div class="frozen">
											<FrozenIcon></FrozenIcon>
										</div>
									)}
									<EditableInput
										type="date"
										value={date}
										onChange={updateListDate}
										editAllowed={isCurrentUsersList && !isFrozen(list!)}
									/>
								</div>
							)}
							{isCurrentUsersList && selectedTab === "items" && (
								<button id="addItem" className="icon-button" onClick={addItem}>
									<AddIcon />
								</button>
							)}
							{isCurrentUsersList && selectedTab === "invited" && (
								<button id="addInvitee" className="icon-button" onClick={navigateToAddUsers}>
									<UserAddIcon />
								</button>
							)}
							{isCurrentUsersList && selectedTab === "settings" && (
								<div id="settingsMenu" className="icon-button">
									<MenuIcon />
									<select ref={settingsMenuSelectEl} onChange={onSettingsMenuChange}>
										<option value="newList" disabled={isFrozen(list!)}>
											Create a new list with your non-taken items
										</option>
										<option disabled={isFrozen(list!)} value="freezeList">
											Freeze your list settings
										</option>
									</select>
								</div>
							)}
						</div>
						<div id="avatar-and-name">
							<h1>
								<EditableInput
									type="text"
									value={name}
									onChange={updateListName}
									editAllowed={isCurrentUsersList}
								/>
							</h1>
							<div class="avatar-container">
								<Avatar userID={list!.owner} showName={true}></Avatar>
							</div>
						</div>
					</header>
					<Tabs tabListAriaLabel="Current lists" tabs={tabs} onTabChange={updateIconButton} />
				</div>
			)}
		</div>
	);
};

import { FunctionComponent } from "preact";
import { useState, useEffect, useRef, useLayoutEffect } from "preact/hooks";
import "./Tabs.css";
import { onScrollEnd } from "../../scroll-end/scroll-end";

interface TabsProps {
	tabListAriaLabel: string;
	tabs: TabOptions[];
	onTabChange?: (fragmentURL: string) => void;
}

export interface TabOptions {
	label: JSX.Element;
	afterButton?: JSX.Element;
	panel: JSX.Element;
	fragmentURL: string;
}

export const Tabs: FunctionComponent<TabsProps> = ({ tabListAriaLabel, tabs, onTabChange }) => {
	const [position, setPosition] = useState(0);
	const snapIndicator = useRef<HTMLDivElement>(null);
	const scrollSnapContainer = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		const id = window.location.hash.slice(1);
		if (!id) return;
		const target = document.getElementById(id);
		target?.scrollIntoView();
	}, []);

	useEffect(() => {
		const translates: string[] = [];
		tabs.forEach((_tab, index) => {
			translates.push(`translateX(${index * 100}%)`);
		});

		// @ts-ignore
		const scrollTimeline = new ScrollTimeline({
			source: scrollSnapContainer.current,
			orientation: "inline",
		});

		snapIndicator.current?.animate(
			{
				transform: translates,
			},
			{
				fill: "both",
				// @ts-ignore
				timeline: scrollTimeline,
			}
		);
	}, [tabs]);

	const movingMultiple = useRef(false);

	function onTabClick(tab: TabOptions) {
		document.getElementById(tab.fragmentURL)?.scrollIntoView({ behavior: "smooth" });
		const url = new URL(location.href);
		url.hash = tab.fragmentURL;
		history.replaceState(null, "", url);

		movingMultiple.current = Math.abs(tabs.indexOf(tab) - position) > 1;

		onScrollEnd(scrollSnapContainer.current!, (event) => {
			movingMultiple.current = false;
			onScroll(event);
		});
	}

	function getTabID(tab: TabOptions): string {
		return tab.fragmentURL + "Tab";
	}

	function onScroll(event: Event) {
		if (!(event.target instanceof HTMLElement)) {
			return;
		}

		const panelWidth = event.target.scrollWidth / tabs.length;
		const scrolledPosition = Math.round(event.target.scrollLeft / panelWidth);
		if (scrolledPosition !== position) {
			setPosition(scrolledPosition);
			if (!movingMultiple.current) {
				onTabChange?.(tabs[scrolledPosition].fragmentURL);
			}
		}
	}

	const buttons = tabs.map((tab, index) => {
		const button = (
			<button
				onClick={() => onTabClick(tab)}
				key={tab.fragmentURL}
				id={getTabID(tab)}
				role="tab"
				aria-controls={tab.fragmentURL}
				aria-selected={index === position}>
				<div className="pill">{tab.label}</div>
			</button>
		);

		if (!tab.afterButton) {
			return button;
		}

		return (
			<div class="tab-container">
				{button}
				{tab.afterButton}
			</div>
		);
	});

	const panels = tabs.map((tab, index) => {
		return (
			<div
				id={tab.fragmentURL}
				role="tabpanel"
				aria-labelledby={getTabID(tab)}
				key={tab.fragmentURL}
				inert={index !== position}>
				{tab.panel}
			</div>
		);
	});

	return (
		<div data-tabs-styles="true">
			<div
				role="tablist"
				aria-label={tabListAriaLabel}
				style={{ gridTemplateColumns: `repeat(${tabs.length}, max-content)` }}>
				{buttons}
				<div style={{ inlineSize: `${100 / tabs.length}%` }} ref={snapIndicator} class="snap-indicator"></div>
			</div>

			<div ref={scrollSnapContainer} className="scroll-snap-container" onScroll={onScroll}>
				{panels}
			</div>
		</div>
	);
};

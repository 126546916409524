import { Link } from "react-router-dom";
import { ListoIcon } from "../../components/icons/ListoIcon";
import "./About.css";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { useEffect } from "preact/hooks";

export function About(props: { backButton?: { to: string } }) {
	useEffect(() => {
		document.title = "Listo - About";
	});

	return (
		<div class="about">
			{props.backButton && (
				<Link to={props.backButton.to} className="icon-button back-button">
					<ArrowIcon></ArrowIcon>
				</Link>
			)}

			<h1>About Listo</h1>

			<div class="content">
				<div class="icon-container">
					<ListoIcon></ListoIcon>
				</div>
				<div class="paragraphs">
					<p>Listo was created by Harry Moore and Tom Newman for family birthday and christmases.</p>
					<p>
						For any queries please contact us via email{" "}
						<a href="mailto:listo1536@gmail.com" class="link" target="_blank">
							listo1536@gmail.com
						</a>
					</p>
				</div>
			</div>
		</div>
	);
}

import { applyActionCode, getAuth } from "@firebase/auth";
import "./VerifyEmail.css";
import { useNavigate } from "react-router";
import { StateUpdater, useEffect } from "react";
import { isEmailVerified } from "../../user/user";
export function VerifyEmail({ code, setEmailVerified }: { code: string; setEmailVerified: StateUpdater<boolean> }) {
	const navigate = useNavigate();
	const auth = getAuth();

	useEffect(() => {
		if (isEmailVerified() || !code) {
			navigate("/home");
		}
	}, [code, navigate]);

	useEffect(() => {
		applyActionCode(auth, code)
			.then(() => {
				if (!auth.currentUser) {
					alert("Your email has been verified. You can now login.");
					navigate("/login");
				} else {
					alert("Your email has been verified.");
					setEmailVerified(true);
					navigate("/home");
				}
			})
			.catch(() => {
				alert("Your email could not be verified. Please try again.");
				navigate("/email");
				return;
			});
	}, [auth, code, navigate, setEmailVerified]);

	return <div class="verify-email"></div>;
}

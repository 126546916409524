import { getAuth } from "firebase/auth";
import { createContext, FunctionComponent } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Nav } from "../../components/Nav/Nav";
import { AddInvitee } from "../AddInvitee/AddInvitee";
import { Items } from "../YourItems/YourItems";
import ChangePassword from "../ChangePassword/ChangePassword";
import Home from "../Home/Home";
import { List } from "../List/List";
import { User } from "../User/User";
import "./LoggedIn.css";
import { About } from "../About/About";
import { OldLists } from "../OldLists/OldLists";
import { getUser, loggedInPreviously, setLoggedInPreviously } from "../../user/user";
import { Friends } from "../Friends/Friends";
import { AddFriends } from "../Friends/AddFriends/AddFriends";
import { NotificationPrompt } from "../../notification/NotificationPrompt";

export interface UserDetails {
	name: string | null; // null if loading.
	setName: React.StateUpdater<string | null>;
	photoURL: string | null; // null if loading.
	setPhotoURL: React.StateUpdater<string | null>;
}

export const UserDetailsContext = createContext<UserDetails | null>(null);

export const LoggedIn: FunctionComponent = () => {
	const auth = getAuth();
	const bigViewportQuery = window.matchMedia("(min-width: 700px)");
	const [bigViewport, setBigViewport] = useState(bigViewportQuery.matches);
	const [name, setName] = useState<string | null>(null);
	const [photoURL, setPhotoURL] = useState<string | null>(null);
	const userDetails = { name, setName, photoURL, setPhotoURL };
	const navigate = useRef(useNavigate());

	useEffect(() => {
		if (!loggedInPreviously()) {
			setLoggedInPreviously();
			navigate.current("/notifications");
		}
	}, []);

	useEffect(() => {
		const listener = (event: MessageEvent<any>) => {
			navigate.current(event.data.messagePayload.url);
		};
		navigator.serviceWorker?.addEventListener("message", listener);
		return () => {
			navigator.serviceWorker?.removeEventListener("message", listener);
		};
	}, []);

	useEffect(() => {
		let resolved = false;
		getUser(auth.currentUser!.uid).then((user) => {
			if (resolved) return;
			resolved = true;
			setName(user.name);
			setPhotoURL(user.photoURL || "");
			localStorage.setItem(`${auth.currentUser!.uid}/name`, user.name);

			if (!user.photoURL) return;
			const oldPhotoURL = localStorage.getItem(`${auth.currentUser!.uid}/photoURL`);
			localStorage.setItem(`${auth.currentUser!.uid}/photoURL`, user.photoURL);

			// If the user has updated their avatar on another device...
			if (oldPhotoURL !== user.photoURL) {
				caches.open("currentUser").then((cache) => {
					if (oldPhotoURL) {
						cache.delete(oldPhotoURL);
					}
					cache.match(user.photoURL!).then(async (match) => {
						if (match) return;
						// Cache.add() doesn't work for some reason
						const result = await fetch(new Request(user.photoURL!, { mode: "no-cors" }));
						cache.put(user.photoURL!, result.clone());
					});
				});
			}
		});
		setTimeout(() => {
			if (resolved) return;
			resolved = true;
			setName(localStorage.getItem(`${auth.currentUser!.uid}/name`));
			setPhotoURL(localStorage.getItem(`${auth.currentUser!.uid}/photoURL`));
		}, 500);
	}, [auth]);

	useEffect(() => {
		const listener = (event: MediaQueryListEvent) => {
			if (event.matches) {
				setBigViewport(true);
			} else {
				setBigViewport(false);
			}
		};
		bigViewportQuery.addEventListener("change", listener);
		return () => {
			bigViewportQuery.removeEventListener("change", listener);
		};
	}, [bigViewportQuery]);

	return (
		<UserDetailsContext.Provider value={userDetails}>
			<div class="logged-in">
				{bigViewport && <Nav></Nav>}
				<main>
					<Routes>
						<Route path="/home" element={<Home />}></Route>
						<Route path="/" element={<Navigate to="/home" />} />
						<Route path="/items" element={<Items date="future" key="future" />}></Route>
						<Route path="/user" element={<User />}></Route>
						<Route path="/list/:listId" element={<List />}></Route>
						<Route path="/list/:listId/add-invitee" element={<AddInvitee />}></Route>
						<Route path="/change-password" element={<ChangePassword />}></Route>
						<Route path="/about" element={<About />}></Route>
						<Route path="/old-lists" element={<OldLists></OldLists>}></Route>
						<Route path="/old-items" element={<Items date="past" key="past" />}></Route>
						<Route path="/friends" element={<Friends></Friends>}></Route>
						<Route path="/add-friends" element={<AddFriends></AddFriends>}></Route>
						<Route path="/notifications" element={<NotificationPrompt></NotificationPrompt>}></Route>
						<Route path="*" element={<Navigate to="/home" replace={true} />}></Route>
					</Routes>
				</main>
				{!bigViewport && <Nav></Nav>}
			</div>
		</UserDetailsContext.Provider>
	);
};

import { getAuth } from "firebase/auth";
import { FunctionComponent } from "preact";
import { Link } from "react-router-dom";
import { deleteInvitee, deleteList, List } from "../../pages/List/list-model";
import { Avatar } from "../Avatar/Avatar";
import { TrashIcon } from "../icons/TrashIcon";
import { UserRemoveIcon } from "../icons/UserRemoveIcon";
import "./ListBox.css";

interface ListBoxProps {
	id: string;
	list: List;
}

export const ListBox: FunctionComponent<ListBoxProps> = ({ id, list }) => {
	const currentUserID = getAuth().currentUser?.uid || "";
	const isCurrentUsersList = list.owner === currentUserID;
	const isCurrentUserAnInvitee = !!list.invitees?.[currentUserID];

	function onDeleteClick() {
		if (confirm(`Are you sure you want to delete ${list.name}?`)) {
			deleteList(id, list);
		}
	}

	function onDeleteInviteeClick() {
		if (confirm(`Are you sure you want to remove yourself from ${list.name}?`)) {
			deleteInvitee(id, currentUserID);
		}
	}

	return (
		<div class="list-box">
			{isCurrentUsersList && (
				<button class="delete-button button" onClick={onDeleteClick}>
					<TrashIcon></TrashIcon>
				</button>
			)}
			{isCurrentUserAnInvitee && (
				<button class="delete-invitee button" onClick={onDeleteInviteeClick}>
					<UserRemoveIcon></UserRemoveIcon>
				</button>
			)}

			<div class="box">
				<div class="link-and-date">
					<Link to={`/list/${id}`} className="link">
						<h2>{list.name}</h2>
					</Link>
					{list.date && <div>{new Intl.DateTimeFormat(undefined).format(new Date(list.date))}</div>}
				</div>
				<div class="owner">
					<Avatar userID={list.owner} showName={true}></Avatar>
				</div>
			</div>
		</div>
	);
};

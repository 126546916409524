import { useSignal } from "@preact/signals";
import "./AddFriends.css";
import { Friends, addFriend, friendsOnValue, removeFriend } from "../friends-model";
import { useEffect, useLayoutEffect, useRef } from "preact/hooks";
import { Link, useLocation } from "react-router-dom";
import { ArrowIcon } from "../../../components/icons/ArrowIcon";
import { SearchIcon } from "../../../components/icons/SearchIcon";
import { minLoading } from "../../..";
import { getAuth } from "firebase/auth";
import { Avatar } from "../../../components/Avatar/Avatar";
import { UserRemoveIcon } from "../../../components/icons/UserRemoveIcon";
import { UserAddIcon } from "../../../components/icons/UserAddIcon";
import { User, searchForUsers } from "../../../user/user";

let inPageNavigation = false;
export function setInPageNavigation(value: boolean) {
	inPageNavigation = value;
}

export function AddFriends() {
	const friends = useSignal<Friends | null>(null);
	const results = useSignal<[string, User][] | null>(null);
	const loading = useSignal(false);
	const location = useLocation();
	const { backDestination = "/friends" } = location.state || {};

	const searchInput = useRef<HTMLInputElement>(null);

	useLayoutEffect(() => {
		if (inPageNavigation) {
			searchInput.current?.focus();
			setInPageNavigation(false);
		}
	}, []);

	useEffect(() => {
		return friendsOnValue((value) => {
			friends.value = value;
		});
	}, [friends]);

	useEffect(() => {
		document.title = "Listo - Add Friends";
	}, []);

	function onKeyDown(event: KeyboardEvent) {
		if (event.key === "Enter") {
			search();
		}
	}

	function search() {
		const value = searchInput.current?.value;
		if (!value) return;
		loading.value = true;
		searchForUsers(searchInput.current!.value, (searchResults) => {
			results.value = searchResults.filter(([userID]) => {
				return userID !== getAuth().currentUser?.uid;
			});
			setTimeout(() => {
				loading.value = false;
			}, minLoading);
		});
	}

	return (
		<div class="add-friends">
			<header>
				<Link to={backDestination} className="icon-button back">
					<ArrowIcon></ArrowIcon>
				</Link>
				<h1>Add Friends</h1>
			</header>
			<div class="content">
				<div class="search">
					<div class="search-input">
						<input type="search" ref={searchInput} onKeyDown={onKeyDown} enterkeyhint="search"></input>
						<button class="icon-button" onClick={search}>
							<SearchIcon></SearchIcon>
						</button>
					</div>
				</div>

				{loading.value && (
					<div class="message">
						<p>Loading</p>
					</div>
				)}
				{!loading.value && results.value !== null && !results.value.length && (
					<div class="message">
						<p>No results</p>
					</div>
				)}
				{!loading.value && !!results.value?.length && (
					<div class="results">
						{results.value.map(([userID, user]) => {
							return (
								<div class="result" key={userID}>
									<div class="avatar-and-name">
										<div class="avatar-container">
											<Avatar userID={userID} user={user}></Avatar>
										</div>
										<h2>{user.name}</h2>
									</div>
									{userID in (friends.value || {}) && (
										<button
											class="icon-button remove-friend-button"
											onClick={() => removeFriend(userID)}>
											<UserRemoveIcon></UserRemoveIcon>
										</button>
									)}
									{!(userID in (friends.value || {})) && (
										<button class="icon-button add-friend-button" onClick={() => addFriend(userID)}>
											<UserAddIcon></UserAddIcon>
										</button>
									)}
								</div>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
}

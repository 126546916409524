import { FunctionComponent } from "preact";

export const MenuIcon: FunctionComponent = () => {
	return (
		<svg viewBox="0 0 512 512">
			<circle fill="currentColor" cx="256" cy="256" r="26" />
			<circle fill="currentColor" cx="346" cy="256" r="26" />
			<circle fill="currentColor" cx="166" cy="256" r="26" />
			<path
				d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
				fill="none"
				stroke="currentColor"
				stroke-miterlimit="10"
				stroke-width="32"
			/>
		</svg>
	);
};

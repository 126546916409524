import { FunctionComponent } from "preact";
import "./Home.css";
import { UserDetailsContext } from "../LoggedIn/LoggedIn";
import { Tabs } from "../../components/Tabs/Tabs";
import { Link, useNavigate } from "react-router-dom";
import { CreateIcon } from "../../components/icons/CreateIcon";
import { createList, List } from "../List/list-model";
import { YoursPanel } from "../../components/YoursPanel/YoursPanel";
import { InvitedPanel } from "../../components/InvitedPanel/InvitedPanel";
import { getAuth } from "firebase/auth";
import { useContext, useEffect } from "preact/hooks";
import { ListoIcon } from "../../components/icons/ListoIcon";

const Home: FunctionComponent = () => {
	const userDetails = useContext(UserDetailsContext)!;
	const currentUserId = getAuth().currentUser?.uid || "";
	const navigate = useNavigate();
	const tabs = [
		{ label: <>Yours</>, panel: <YoursPanel userID={currentUserId} date="future" />, fragmentURL: "yours" },
		{ label: <>Invited</>, panel: <InvitedPanel userID={currentUserId} date="future" />, fragmentURL: "invited" },
	];

	useEffect(() => {
		document.title = "Listo - Lists";
	}, []);

	function create() {
		const list: List = {
			name: `${userDetails.name || ""}'s list`,
			owner: currentUserId,
			date: "",
			inviteesCanSee: false,
			ownerCanSee: false,
			ownerCanTake: false,
		};

		const listId = createList(list);
		navigate(`/list/${listId}`);
	}

	return (
		<div data-home-styles="true">
			<div id="content">
				<header>
					<Link to={"/home"} className="link listo-icon">
						<ListoIcon states={true}></ListoIcon>
					</Link>
					<h1>Lists</h1>
					<button id="create-list" class="icon-button" onClick={create}>
						<CreateIcon />
					</button>
				</header>

				<Tabs tabListAriaLabel="Lists" tabs={tabs} />
			</div>
		</div>
	);
};

export default Home;

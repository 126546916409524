import { getAuth } from "firebase/auth";
import { YoursPanel } from "../../components/YoursPanel/YoursPanel";
import { Tabs } from "../../components/Tabs/Tabs";
import "./OldLists.css";
import { Link } from "react-router-dom";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { InvitedPanel } from "../../components/InvitedPanel/InvitedPanel";
import { useEffect } from "preact/hooks";

export function OldLists() {
	const currentUserId = getAuth().currentUser!.uid;

	useEffect(() => {
		document.title = "Listo - Old Lists";
	}, []);

	const tabs = [
		{ label: <>Yours</>, panel: <YoursPanel userID={currentUserId} date="past" />, fragmentURL: "yours" },
		{ label: <>Invited</>, panel: <InvitedPanel userID={currentUserId} date="past" />, fragmentURL: "invited" },
	];

	return (
		<div class="old-lists">
			<header>
				<Link to={"/home"} className="link listo-icon">
					<ListoIcon></ListoIcon>
				</Link>
				<h1>Old Lists</h1>
			</header>

			<Tabs tabListAriaLabel="Old lists" tabs={tabs} />
		</div>
	);
}

import { FunctionComponent } from "preact";
import { useState, useRef, useEffect } from "preact/hooks";
import { PencilIcon } from "../icons/PencilIcon";
import DOMPurify from "dompurify";
import "./EditableInput.css";
import { useLayoutEffect } from "react";

export interface EditableInputProps {
	type: "currency" | "date" | "text" | "textarea";
	value: string;
	onChange: (value: string) => void;
	editAllowed: boolean;
}

// Not the best idea for mobile devices with keyboards but web...
const usingVirtualKeyboard = "ontouchstart" in document.documentElement;

export const EditableInput: FunctionComponent<EditableInputProps> = ({ type, value, onChange, editAllowed }) => {
	const [disabled, setDisabled] = useState(true);
	const inputElement = useRef<HTMLInputElement>(null);
	const divElement = useRef<HTMLDivElement>(null);
	const hidingNav = useRef(false);
	let element: string;
	if (type === "text" || type === "textarea" || type === "currency") {
		element = "div";
	} else {
		element = "input";
	}

	useEffect(() => {
		return () => {
			window.setTimeout(() => {
				if (!hidingNav.current) return;

				const bottomNav = document.querySelector<HTMLElement>("main + nav");
				bottomNav!.style.visibility = "visible";
			}, 150);
		};
	}, []);

	useLayoutEffect(() => {
		if (type === "date" || !usingVirtualKeyboard) return;

		if (disabled) {
			const timeout = window.setTimeout(() => {
				const bottomNav = document.querySelector<HTMLElement>("main + nav");
				if (!disabled || !bottomNav) return;
				bottomNav!.style.visibility = "visible";
				hidingNav.current = false;
			}, 150);
			return () => {
				clearTimeout(timeout);
			};
		} else {
			const bottomNav = document.querySelector<HTMLElement>("main + nav");
			if (!bottomNav) return;
			bottomNav!.style.visibility = "hidden";
			hidingNav.current = true;
		}
	}, [disabled, type]);

	function onBlur() {
		setDisabled(true);

		// When deleting all content in Safari in a content editable element a single <br> can
		// remain. In this case remove treat the value as "".
		if (value === "<br>") {
			value = "";
		}
		if (type === "text") {
			// Prevent HTML going in. iOS format in UIEditMenu can make that happen.
			value = divElement.current?.innerText.trim() || "";
			divElement.current!.innerHTML = value;
		}
		onChange(value);
	}

	function edit() {
		setDisabled(false);
		if (element === "input") {
			inputElement.current!.hidden = false;
			inputElement.current?.focus();
			if (type === "date") {
				inputElement.current?.showPicker();
			}
		}
		if (element === "div") {
			// Need to do this in addition to the following to ensure the virtual keyboard appears on Android.
			divElement.current!.focus();

			const selection = window.getSelection();
			selection?.selectAllChildren(divElement.current!);
		}
	}

	function onInput(event: Event) {
		if (event.currentTarget instanceof HTMLInputElement) {
			value = event.currentTarget.value;
		} else if (event.currentTarget instanceof HTMLDivElement) {
			value = event.currentTarget.innerHTML || "";
		}
	}

	const allowedCurrencyKeys = new Set(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."]);

	function onKeyDown(event: KeyboardEvent) {
		if ((type === "text" || type === "currency") && event.key === "Enter") {
			event.preventDefault();
			document.body.focus();
		}
	}

	function getEnterKeyHint() {
		if (type === "text" || type === "currency") {
			return "done";
		}
	}

	function onBeforeInput(event: Event) {
		if (!(event instanceof InputEvent) || type !== "currency") return;

		if (event.data === "." && value.includes(".")) {
			event.preventDefault();
			return;
		}

		if (
			allowedCurrencyKeys.has(event.data!) ||
			event.inputType === "deleteContentBackward" ||
			event.inputType === "historyUndo"
		) {
			return;
		}
		event.preventDefault();
	}

	return (
		<div data-editable-input-styles data-type={type} class={editAllowed ? "edit-allowed" : "edit-disabled"}>
			{editAllowed && (
				<button className="icon-button" onClick={edit} type="button">
					<PencilIcon></PencilIcon>
				</button>
			)}
			<div class="input-container">
				{element === "input" && (
					<input
						type={type}
						ref={inputElement}
						value={value}
						disabled={!editAllowed}
						class={disabled ? "disabled" : ""}
						onKeyDown={onKeyDown}
						onFocus={() => setDisabled(false)}
						onBlur={onBlur}
						onInput={onInput}
						hidden={type === "date" && !value && disabled}
					/>
				)}
				{type === "date" && !value && disabled && (
					<div class="empty-date" onClick={edit}>
						<span>dd</span>/<span>mm</span>/<span>yyyy</span>
					</div>
				)}
				{type === "currency" && (
					<div class="currency-container">
						<div class="pound">£</div>
						<div
							ref={divElement}
							contentEditable={editAllowed}
							class={disabled ? "disabled" : ""}
							onBlur={onBlur}
							onInput={onInput}
							inputMode="decimal"
							onKeyDown={onKeyDown}
							onFocus={() => setDisabled(false)}
							enterkeyhint={getEnterKeyHint()}
							onBeforeInput={onBeforeInput}
							dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}></div>
					</div>
				)}
				{(type === "text" || type === "textarea") && (
					<div
						ref={divElement}
						contentEditable={editAllowed}
						class={disabled ? "disabled" : ""}
						onBlur={onBlur}
						onInput={onInput}
						onKeyDown={onKeyDown}
						onFocus={() => setDisabled(false)}
						enterkeyhint={getEnterKeyHint()}
						dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}></div>
				)}
			</div>
		</div>
	);
};

import { FunctionComponent } from "preact";

export const ArrowIcon: FunctionComponent = () => {
	return (
		<svg viewBox="0 0 512 512">
			<path
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="48"
				d="M244 400L100 256l144-144M120 256h292"
			/>
		</svg>
	);
};

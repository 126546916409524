import { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import "./ChangePassword.css";
import { useNavigate } from "react-router";
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "firebase/auth";
import { Link } from "react-router-dom";
import { ArrowIcon } from "../../components/icons/ArrowIcon";
import { connected } from "../..";

const ChangePassword: FunctionComponent = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Listo - Change Password";
	}, []);

	function handleInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	function handleNewPasswordInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		const confirmPassword = document.getElementById("confirmPassword") as HTMLInputElement;

		if (event.currentTarget.value.length >= 6) {
			event.currentTarget.setCustomValidity("");
		} else {
			event.currentTarget.setCustomValidity("Passwords must be at least 6 characters long");
		}

		if (event.currentTarget.value === confirmPassword.value) {
			confirmPassword.setCustomValidity("");
		} else {
			confirmPassword.setCustomValidity("Passwords do not match");
		}

		handleInput(event);
	}

	function handleConfirmPasswordInput(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.value === (document.getElementById("newPassword") as HTMLInputElement).value) {
			event.currentTarget.setCustomValidity("");
		} else {
			event.currentTarget.setCustomValidity("Passwords do not match");
		}

		handleInput(event);
	}

	function handleBlur(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		event.currentTarget.setAttribute("data-touched", "true");
	}

	function handleFocus(event: Event) {
		if (!(event.currentTarget instanceof HTMLInputElement)) {
			return;
		}

		if (event.currentTarget.hasAttribute("data-touched")) {
			event.currentTarget.reportValidity();
		}
	}

	function handleChangePassword(event: MouseEvent) {
		event.preventDefault();
		const oldPassword = document.getElementById("oldPassword") as HTMLInputElement;
		const newPassword = document.getElementById("newPassword") as HTMLInputElement;
		const confirmPassword = document.getElementById("confirmPassword") as HTMLInputElement;
		const auth = getAuth();
		const currentUser = auth.currentUser;
		const email = currentUser?.email;

		oldPassword.setAttribute("data-touched", "true");
		newPassword.setAttribute("data-touched", "true");
		confirmPassword.setAttribute("data-touched", "true");

		if (newPassword.value && oldPassword.value === newPassword.value) {
			newPassword.setCustomValidity("New password cannot be the same as old password");
		}

		if (newPassword.value !== confirmPassword.value) {
			confirmPassword.setCustomValidity("Password confirmation does not match");
		}

		if (currentUser && email && document.querySelector("form")?.reportValidity()) {
			const credential = EmailAuthProvider.credential(email, oldPassword.value);

			setLoading(true);
			reauthenticateWithCredential(currentUser, credential)
				.then(() => {
					return updatePassword(currentUser, newPassword.value).then(() => {
						alert("Your password has been changed");
						navigate("/user");
					});
				})
				.catch((error) => {
					if (error.code === "auth/wrong-password") {
						window.alert("Incorrect current password");
					} else {
						window.alert(error.message);
					}
					setLoading(false);
				});
		}
	}

	return (
		<div data-change-password-styles="true">
			<header>
				<Link to="/user" className="icon-button back">
					<ArrowIcon></ArrowIcon>
				</Link>
				<h1>Change Password</h1>
			</header>
			<div id="content">
				<form>
					<div>
						<label htmlFor="oldPassword">Current Password:</label>
						<input
							id="oldPassword"
							type="password"
							required
							autoComplete="current-password"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleInput}
							enterkeyhint="go"
						/>
					</div>
					<div>
						<label htmlFor="newPassword">New Password:</label>
						<input
							id="newPassword"
							type="password"
							required
							autoComplete="new-password"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleNewPasswordInput}
							enterkeyhint="go"
						/>
					</div>
					<div>
						<label htmlFor="confirmPassword">Re-Enter New Password:</label>
						<input
							id="confirmPassword"
							type="password"
							required
							autoComplete="new-password"
							onBlur={handleBlur}
							onFocus={handleFocus}
							onInput={handleConfirmPasswordInput}
							enterkeyhint="go"
						/>
					</div>
					<button
						className="button"
						data-primary="true"
						onClick={handleChangePassword}
						disabled={loading || !connected.value}>
						Change Password
					</button>
				</form>
			</div>
		</div>
	);
};

export default ChangePassword;

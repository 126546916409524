export function onScrollEnd(target: EventTarget, listener: (event: Event) => void) {
	const delay = 50;

	let scrollTimeout: number | null = null;
	const onScroll = (event: Event) => {
		if (scrollTimeout !== null) {
			clearTimeout(scrollTimeout);
		}
		scrollTimeout = window.setTimeout(() => {
			listener(event);
			target.removeEventListener("scroll", onScroll);
		}, delay);
	};
	target.addEventListener("scroll", onScroll);
}

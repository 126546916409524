import { useEffect, useState } from "preact/hooks";
import { connected } from "../../index";
import "./ConnectionStrip.css";

export function ConnectionStrip() {
	const [showConnected, setShowConnected] = useState(false);
	const [showDisconnected, setShowDisconnected] = useState(false);

	useEffect(() => {
		let firstValue = true;
		return connected.subscribe((value) => {
			if (value) {
				if (!firstValue) {
					setShowDisconnected(false);
					setShowConnected(true);
					setTimeout(() => {
						setShowConnected(false);
					}, 3000);
				}
			} else {
				setShowDisconnected(true);
			}
			firstValue = false;
		});
	}, []);

	function getClass() {
		if (showConnected) {
			return "connected";
		}
		if (showDisconnected) {
			return "disconnected";
		}

		return "";
	}

	if (!showConnected && !showDisconnected) return <></>;

	return (
		<div id="connection-strip" class={getClass()}>
			{showConnected && "Connected"}
			{showDisconnected && "Not connected"}
		</div>
	);
}

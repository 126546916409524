import { getAuth } from "firebase/auth";
import { useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";
import { Item } from "../../components/Item/Item";
import { takenItemsOnValue } from "../../user/user";
import { FirebaseArray } from "../../util";
import "./YourItems.css";
import { ListoIcon } from "../../components/icons/ListoIcon";
import { Link } from "react-router-dom";
import { minLoading } from "../../index";
import { Item as ItemModel, isHidden, itemOnValue } from "../../components/Item/item-model";
import { List, listOnValue } from "../List/list-model";
import { Unsubscribe } from "firebase/database";
import { Avatar } from "../../components/Avatar/Avatar";

interface ItemsProps {
	date: "past" | "future";
}

export const Items = (props: ItemsProps) => {
	const [items, setItems] = useState<FirebaseArray>({});
	const [itemModels, setItemModels] = useState<{ [itemID: string]: ItemModel }>({});
	const [lists, setLists] = useState<{ [listID: string]: List }>({});
	const firstLoad = useRef<boolean>(true);
	const fetchedItems = useRef<string[]>([]);
	const fetchedLists = useRef<string[]>([]);
	const itemUnsubs = useRef<{ [itemID: string]: Unsubscribe }>({});
	const listUnsubs = useRef<{ [listID: string]: Unsubscribe }>({});
	const [loading, setLoading] = useState(true);

	const heading = props.date === "future" ? "Items" : "Old Items";

	useEffect(() => {
		document.title = `Listo - ${heading}`;
	}, [heading]);

	useEffect(() => {
		return takenItemsOnValue(getAuth().currentUser!.uid, (items) => {
			if (!items) {
				setItems({});
				setTimeout(() => {
					setLoading(false);
					firstLoad.current = false;
				}, minLoading);
				return;
			}

			setItems(items);

			let itemsToLoad: number | null = null;
			let itemsLoaded: number | null = null;
			let listsToLoad: number | null = null;
			let listsLoaded: number | null = null;
			if (firstLoad.current) {
				itemsToLoad = Object.keys(items || {}).length;
				itemsLoaded = 0;
				listsToLoad = itemsToLoad;
				listsLoaded = 0;
			}

			for (const itemID in items) {
				if (fetchedItems.current.includes(itemID)) {
					continue;
				}
				fetchedItems.current.push(itemID);
				let itemFirstLoad = true;
				itemUnsubs.current[itemID] = itemOnValue(itemID, (item) => {
					if (item === null) {
						setItemModels((items) => {
							delete items[itemID];
							return { ...items };
						});
						itemUnsubs.current[itemID]();
						delete itemUnsubs.current[itemID];
						fetchedItems.current = fetchedItems.current.filter((item) => {
							return item !== itemID;
						});
					} else {
						if (itemFirstLoad && firstLoad.current && itemsLoaded !== null) {
							itemFirstLoad = false;
							itemsLoaded++;
						}

						setItemModels((items) => {
							return { ...items, ...{ [itemID]: item } };
						});

						if (fetchedLists.current.includes(item.fromList)) {
							if (firstLoad.current && listsToLoad !== null && listsLoaded !== null) {
								listsToLoad--;
								if (listsLoaded >= listsToLoad) {
									firstLoad.current = false;
									setTimeout(() => {
										setLoading(false);
									}, minLoading);
								}
							}
							return;
						}
						fetchedLists.current.push(item.fromList);

						let listFirstLoad = true;
						listUnsubs.current[item.fromList] = listOnValue(item.fromList, (list) => {
							if (list === null) {
								setLists((lists) => {
									delete lists[item.fromList];
									return { ...lists };
								});
								listUnsubs.current[item.fromList]();
								delete listUnsubs.current[item.fromList];
								fetchedLists.current = fetchedLists.current.filter((list) => {
									return list !== item.fromList;
								});
							} else {
								setLists((oldLists) => {
									return { ...oldLists, ...{ [item.fromList]: list } };
								});

								if (
									listFirstLoad &&
									firstLoad.current &&
									listsLoaded !== null &&
									listsToLoad !== null
								) {
									listFirstLoad = false;
									listsLoaded++;
									if (listsLoaded >= listsToLoad) {
										firstLoad.current = false;
										setTimeout(() => {
											setLoading(false);
										}, minLoading);
									}
								}
							}
						});
					}
				});
			}
		});
	}, []);

	useEffect(() => {
		return () => {
			// eslint-disable-next-line react-hooks/exhaustive-deps
			for (const unsub of Object.values(itemUnsubs.current)) {
				unsub();
			}

			// eslint-disable-next-line react-hooks/exhaustive-deps
			for (const unsub of Object.values(listUnsubs.current)) {
				unsub();
			}
		};
	}, []);

	useEffect(() => {
		// Find all items that are no longer taken, remove them and unsub.
		setItemModels((oldItemModels) => {
			const itemModels = { ...oldItemModels };
			for (const itemID in oldItemModels) {
				if (!items?.[itemID]) {
					itemUnsubs.current[itemID]();
					delete itemUnsubs.current[itemID];
					fetchedItems.current = fetchedItems.current.filter((item) => {
						return item !== itemID;
					});
					delete itemModels[itemID];
				}
			}
			return itemModels;
		});
	}, [items]);

	useLayoutEffect(() => {
		// Find all lists that no longer have a taken item, remove them and unsub.
		setLists((oldLists) => {
			const lists = { ...oldLists };
			for (const listID in oldLists) {
				if (
					!Object.values(itemModels).some((item) => {
						return item.fromList === listID;
					})
				) {
					listUnsubs.current[listID]();
					delete listUnsubs.current[listID];
					delete lists[listID];
				}
			}
			return lists;
		});
	}, [itemModels]);

	const listAndItemJSX = Object.entries(lists)
		.filter(([listID, list]) => {
			const items = Object.entries(itemModels).filter(([_itemID, item]) => {
				return item.fromList === listID && !isHidden(list, item, props.date);
			});
			return !!items.length;
		})
		.sort(([listIDA, listA], [listIDB, listB]) => {
			const dateA = listA.date;
			const dateB = listB.date;

			if ((!dateA && !dateB) || dateA === dateB) {
				// Sort by earliest taken time.
				const aTakenTimes = Object.entries(itemModels)
					.filter(([_itemID, item]) => {
						return item.fromList === listIDA;
					})
					.map(([itemID]) => {
						return items![itemID];
					})
					.sort((a, b) => {
						return a - b;
					});
				const bTakenTimes = Object.entries(itemModels)
					.filter(([_itemID, item]) => {
						return item.fromList === listIDB;
					})
					.map(([itemID]) => {
						return items![itemID];
					})
					.sort((a, b) => {
						return a - b;
					});

				return aTakenTimes[0] - bTakenTimes[0];
			}

			if (!dateA) {
				return 1;
			}

			if (!dateB) {
				return -1;
			}

			return new Date(dateA).getTime() - new Date(dateB).getTime();
		})
		.map(([listID, list]) => {
			const itemJSXForList = Object.entries(itemModels)
				.filter(([_itemID, item]) => {
					return item.fromList === listID;
				})
				.sort(([itemIDA], [itemIDB]) => {
					const aTakenTime = items![itemIDA];
					const bTakenTime = items![itemIDB];
					return aTakenTime - bTakenTime;
				})
				.map(([itemID]) => {
					return (
						<Item
							key={itemID}
							id={itemID}
							editAllowed={false}
							nameIsALink={true}
							list={list}
							item={itemModels[itemID]}></Item>
					);
				});

			return (
				<div class="list">
					<div class="list-info">
						<h1 class="name-and-avatar">
							<div class="name">{list.name}</div>
							<div class="avatar-container">
								<Avatar userID={list.owner} showName={true}></Avatar>
							</div>
						</h1>
						{list.date && (
							<h2 class="date">{new Intl.DateTimeFormat(undefined).format(new Date(list.date))}</h2>
						)}
					</div>
					<div class="list-items">{itemJSXForList}</div>
				</div>
			);
		});

	return (
		<div class="items">
			<header>
				<Link to={"/home"} className="link listo-icon">
					<ListoIcon states={true}></ListoIcon>
				</Link>
				<h1>{heading}</h1>
			</header>

			{loading && (
				<div class="message-container">
					<p>Loading...</p>
				</div>
			)}

			{!loading && !listAndItemJSX.length && (
				<div class="message-container">
					<p>You have not taken any items!</p>
				</div>
			)}

			<div class="all-items">{!loading && listAndItemJSX}</div>
		</div>
	);
};

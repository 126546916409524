import { FunctionComponent } from "preact";
import "./Nav.css";
import { HomeIcon } from "../icons/HomeIcon";
import { ItemsIcon } from "../icons/ItemsIcon";
import { NavLink } from "react-router-dom";
import { Avatar } from "../Avatar/Avatar";
import { getAuth } from "firebase/auth";
import { UsersIcon } from "../icons/UsersIcon";

export const Nav: FunctionComponent = () => {
	const userId = getAuth().currentUser?.uid || "";
	return (
		<nav data-nav-styles="true">
			<NavLink className={({ isActive }) => (isActive ? "current-page" : "")} to="/home">
				<div class="anchor-content">
					<div className="pill">
						<HomeIcon></HomeIcon>
					</div>
				</div>
			</NavLink>
			<NavLink className={({ isActive }) => (isActive ? "current-page" : "")} to="/items">
				<div class="anchor-content">
					<div className="pill">
						<ItemsIcon></ItemsIcon>
					</div>
				</div>
			</NavLink>
			<NavLink className={({ isActive }) => (isActive ? "current-page" : "")} to="/friends">
				<div class="anchor-content">
					<div className="pill">
						<UsersIcon></UsersIcon>
					</div>
				</div>
			</NavLink>
			<NavLink className={({ isActive }) => (isActive ? "current-page" : "")} to="/user">
				<div class="anchor-content">
					<div className="pill">
						<Avatar userID={userId}></Avatar>
					</div>
				</div>
			</NavLink>
		</nav>
	);
};
